import { IonPage, IonContent, IonButton, IonItem, IonLabel, IonInput, useIonAlert } from '@ionic/react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AfnHeader from '../../components/AfnHeader';
import Markdown from '../../components/Markdown';
import { MeasurementType } from '../../lib/ApiModel';
import { asNumber } from '../../lib/helper/StringHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';
import CalculatorButton from './measurementtypes/CalculatorButton';

type CollectUsingStrategyProps = {
    siteData: ProjectAssetSiteData;
};

// whitelist of supported measurement types to help exclude ones like "Site Context" which use GIS and can't be collected via the app
export const SupportedMeasurementTypes: MeasurementType[] = [
    'StepCount',
    'HeightOfAverageTree',
    'Manual',
    'AverageOf2',
    'AverageOf5',
];

const CollectUsingStrategy: React.FC<CollectUsingStrategyProps> = ({ siteData }) => {
    const { strategyid } = useParams<{ strategyid: string }>();
    const indicators = siteData.indicatorsForStrategy(strategyid);
    if (indicators.length == 0) return null;
    const measurementStrategy = indicators[0].measurementStrategy;

    const [values, setValues] = useState<(number | undefined)[]>(
        indicators.map((i) => siteData.valueForIndicator(i.id))
    );
    const history = useHistory();
    const [errorPopup] = useIonAlert();
    const updateValueAtIdx = (idx: number, value: number | undefined) => {
        const newValues = values.slice();
        newValues.splice(idx, 1, value);
        setValues(newValues);
    };

    const validate = () => {
        for (let idx = 0; idx < indicators.length; idx++) {
            const ind = indicators[idx];
            const val = values[idx];
            const min = ind.measurementStrategy.minimum;
            const max = ind.measurementStrategy.maximum;

            if (siteData.valueForIndicator(ind.id) != val) {
                if (typeof val != 'number') continue;
                if (typeof min == 'number') {
                    if (val < min) {
                        errorPopup(`${ind.name} must be greater than or equal to ${min}`, [{ text: 'OK' }]);
                        return false;
                    }
                }
                if (typeof max == 'number') {
                    if (val > max) {
                        errorPopup(`${ind.name} must be less than or equal to ${max}`, [{ text: 'OK' }]);
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const doSave = async () => {
        if (!validate()) return;
        //only record updates for indicators which actually changed
        for (let idx = 0; idx < indicators.length; idx++) {
            const ind = indicators[idx];
            if (siteData.valueForIndicator(ind.id) != values[idx]) {
                await siteData.updateValueForIndicator(ind.id, values[idx]);
            }
        }
        history.goBack();
    };

    const isAverage = ['AverageOf2', 'AverageOf5'].includes(measurementStrategy.measurementType);

    return (
        <IonPage>
            <AfnHeader showBackButton={true}>Collect Data</AfnHeader>
            <IonContent>
                <div className="ion-padding">
                    <h2>{measurementStrategy.name}</h2>
                    <Markdown source={measurementStrategy.description} />
                </div>

                <div className="ion-padding">
                    <CalculatorButton
                        measurementStrategy={measurementStrategy}
                        onChange={setValues}
                        indicators={indicators}
                    />
                </div>
                {indicators.map((i, idx) => (
                    <IonItem key={i.id}>
                        <IonLabel position="stacked">
                            {isAverage && !i.name.startsWith('Average') ? `Average ${i.name}` : i.name}
                        </IonLabel>
                        <IonInput
                            type="number"
                            step="any"
                            max={
                                typeof measurementStrategy.maximum == 'number'
                                    ? String(measurementStrategy.maximum)
                                    : undefined
                            }
                            min={
                                typeof measurementStrategy.minimum == 'number'
                                    ? String(measurementStrategy.minimum)
                                    : undefined
                            }
                            value={values[idx]}
                            onIonChange={(e) =>
                                updateValueAtIdx(idx, e.detail.value ? asNumber(e.detail.value) : undefined)
                            }
                        />
                    </IonItem>
                ))}

                <div className="ion-padding">
                    <IonButton expand="block" onClick={() => doSave()}>
                        Save
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CollectUsingStrategy;
