import { EventEmitter } from '../helper/EventHelper';
import { ProjectSummary } from '../ApiModel';
import storage from '../Storage';

export default class ProjectsStore {
    onChange = new EventEmitter<ProjectsStore>();
    projects: ProjectSummary[] = [];

    async load() {
        this.projects = await storage.getProjects();
        this.onChange.fire(this);
    }

    getById(projectId: string) {
        return this.projects.find((p) => p.id == projectId);
    }
}
