import { generateId } from './helper/IdHelper';
import {
    AssetYear,
    ProjectSummary,
    ProjectAsset,
    Indicator,
    AssessmentUnit,
    Site,
    MeasurementData,
    Attachment,
    AttachmentType,
} from './ApiModel';
import AssetStore from './stores/AssetStore';
import AssetYearStore from './stores/AssetYearStore';
import { readFileToDataUrl, thumbNailUrlFromImageData } from './helper/AttachmentHelper';
import { distinct } from './helper/ArrayHelper';

/**
 * A collection of helper functions for working with project data for a particular asset and site
 */

export default class ProjectAssetSiteData {
    site: Site;
    assessmentUnit: AssessmentUnit;
    measurementData: MeasurementData;
    siteIndicators: Indicator[];

    constructor(
        public project: ProjectSummary,
        public asset: ProjectAsset,
        public assetYear: AssetYear,
        allIndicators: Indicator[],
        siteId: string,
        private assetStore: AssetStore,
        private assetYearStore: AssetYearStore
    ) {
        // get AssessmentUnit
        const au = this.asset.subAssets
            .flatMap((sa) => sa.assessmentUnits)
            .find((au) => au.sites.find((s) => s.id == siteId));
        if (!au) throw new Error(`Couldn't find assessment unit for site with id ${siteId}`);
        this.assessmentUnit = au;

        // get Site
        const site = this.assessmentUnit.sites.find((s) => s.id == siteId);
        if (!site) throw new Error(`Couldn't find site with id ${siteId}`);
        this.site = site;

        // get Measurement Data
        const measurementData = assetYear.measurementData.find((md) => md.assessmentUnitId == this.assessmentUnit.id);
        if (!measurementData)
            throw new Error(`Couldn't find measurementData for assessment Unit ${this.assessmentUnit.id}`);
        this.measurementData = measurementData;

        // derive the required indicators from those on measurementData to prevent having to duplicate business logic
        const indicatorIds = distinct(this.measurementData.indicatorData.map((id) => id.indicatorId));
        this.siteIndicators = allIndicators.filter(
            (i) => i.measurementStrategy.measuredAtSites && indicatorIds.includes(i.id)
        );
    }

    get sitePhotos() {
        const attachmentDetails = this.siteIndicators
            .flatMap((i) => i.measurementStrategy.attachmentDetails)
            .filter((a) => !a.indicatorSpecific);
        return distinct(attachmentDetails, (s) => s.fileName);
    }

    get sitePhotoAttachments() {
        return this.measurementData.siteData.find((sd) => sd.siteId == this.site.id)?.attachments ?? [];
    }

    get sitePhotoCollectionComplete() {
        return this.sitePhotos.filter((sp) => this.attachmentForPhoto(sp.fileName) === undefined).length == 0;
    }

    get siteMeasurementCollectionComplete() {
        return this.siteIndicators.find((ind) => !this.indicatorCollectionComplete(ind.id)) == undefined;
    }

    indicatorCollectionComplete(indicatorId: string) {
        return this.valueForIndicator(indicatorId) !== undefined;
    }

    strategyCollectionComplete(strategyId: string) {
        return (
            this.indicatorsForStrategy(strategyId).find((i) => !this.indicatorCollectionComplete(i.id)) === undefined
        );
    }

    attachmentForPhoto(name: string) {
        return this.sitePhotoAttachments.find((spa) => spa.name == name);
    }

    indicatorsForStrategy(strategyId: string) {
        return this.siteIndicators.filter((i) => i.measurementStrategy.id == strategyId);
    }

    valueForIndicator(indicatorId: string): number | undefined {
        const val = this.measurementData.indicatorData.find(
            (id) => id.indicatorId == indicatorId && id.siteId == this.site.id
        )?.measurementValue;
        return val === null ? undefined : val;
    }

    updateValueForIndicator(indicatorId: string, value: number | undefined): Promise<void> {
        console.log('updating indicator value for id', indicatorId, value);
        return this.assetYearStore.updateMeasurement({
            assetId: this.asset.id,
            indicatorId: indicatorId,
            projectId: this.project.id,
            assessmentUnitId: this.assessmentUnit.id,
            siteId: this.site.id,

            year: this.assetYear.year,
            update: {
                measurementValue: value,
                measurementDate: new Date().toISOString(),
            },
        });
    }

    updateSiteNameAndLocation(name: string, lat?: number, lng?: number) {
        this.assetStore.updateSite({
            updateKind: 'nameAndLocation',
            assetId: this.asset.id,
            projectId: this.project.id,
            site: {
                id: this.site.id,
                assessmentUnitId: this.site.assessmentUnitId,
                attachments: this.site.attachments,
                // TODO fix the types so this remains optional
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                location: lat && lng ? { ...this.site.location, type: 'Point', coordinates: [lng, lat] } : undefined!,
                name: name,
            },
        });
    }
    createSiteAttachment(fileUrl: string, name: string, attachmentType: AttachmentType): Promise<Attachment> {
        return this.assetYearStore.createAttachment({
            assessmentUnitId: this.assessmentUnit.id,
            assetId: this.asset.id,
            projectId: this.project.id,
            siteId: this.site.id,
            year: this.assetYear.year,
            attachmentType: attachmentType,
            filename: name,
            localpath: fileUrl,
            attachmentId: 'new-' + generateId(),
        });
    }

    updateSiteAttachment(dataUrl: string, filename: string, existingAttachmentId: string) {
        return this.assetYearStore.replaceAttachment({
            assessmentUnitId: this.assessmentUnit.id,
            assetId: this.asset.id,
            projectId: this.project.id,
            siteId: this.site.id,
            year: this.assetYear.year,
            filename: filename,
            localpath: dataUrl,
            attachmentId: existingAttachmentId,
        });
    }

    deleteSiteAttachment(existingAttachmentId: string) {
        return this.assetYearStore.deleteAttachment({
            assessmentUnitId: this.assessmentUnit.id,
            assetId: this.asset.id,
            projectId: this.project.id,
            siteId: this.site.id,
            year: this.assetYear.year,
            attachmentId: existingAttachmentId,
        });
    }

    getAttachmentFilepath(attachment: Attachment) {
        const entry = this.assetYearStore.attachmentCacheStore.entries.find((e) => e.attachmentId == attachment.id);
        return entry?.localfilepath;
    }

    getAttachmentAsDataUrl(attachment: Attachment) {
        const path = this.getAttachmentFilepath(attachment);
        if (path) return readFileToDataUrl(path, attachment.contentType);
    }

    async getAttachmentThumbnailDataUrl(attachment: Attachment, width: number, height: number) {
        const dataurl = await this.getAttachmentAsDataUrl(attachment);
        if (dataurl) {
            return thumbNailUrlFromImageData(dataurl, width, height);
        }
    }
}
