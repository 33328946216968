import {
    IonContent,
    IonList,
    IonButton,
    IonLabel,
    IonItem,
    IonProgressBar,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonInput,
    useIonAlert,
} from '@ionic/react';
import produce from 'immer';
import { useState } from 'react';
import { Indicator } from '../../../lib/ApiModel';
import { compact, range, sum } from '../../../lib/helper/ArrayHelper';
import { CalculatorProps } from './CalculatorButton';
import { chevronBackSharp, chevronForwardSharp } from 'ionicons/icons';
import { asNumber, formatNumber } from '../../../lib/helper/StringHelper';

type SampleAverageProps = CalculatorProps & {
    sampleCount: number;
    min?: number;
    max?: number;
};

type IndicatorValue = {
    indicator: Indicator;
    value: number | undefined;
};

type Sample = {
    sample: number;
    values: IndicatorValue[];
};

export default function SampleAverageCalculator({ min, max, indicators, onChange, sampleCount }: SampleAverageProps) {
    const [currentSample, setCurrentSample] = useState<number>(1);
    const [sampleData, setSampleData] = useState<Sample[]>(
        range(1, sampleCount).map((i) => ({
            sample: i,
            values: indicators.map((i) => ({ indicator: i, value: undefined })),
        }))
    );
    const completed = currentSample > sampleCount;
    const [errorPopup] = useIonAlert();

    const setIndicatorValue = (sample: number, indicatorId: string, value: number | undefined) => {
        console.log(`Setting indicator value ${value}`);
        const updated = produce(sampleData, (sd) => {
            const thisSample = sd.find((s) => s.sample == sample);
            if (!thisSample) return;
            const thisVal = thisSample.values.find((v) => v.indicator.id == indicatorId);
            if (!thisVal) return;
            thisVal.value = value;
        });

        setSampleData(updated);
    };

    const getIndicatorValue = (sample: number, indicatorId: string) => {
        const thisStep = sampleData.find((s) => s.sample == sample);
        return thisStep?.values.find((v) => v.indicator.id == indicatorId)?.value;
    };

    const validate = () => {
        for (const i of indicators) {
            const val = getIndicatorValue(currentSample, i.id);
            if (typeof val != 'number') continue;
            if (typeof min == 'number') {
                if (val < min) {
                    errorPopup(`${i.name} must be greater than or equal to ${min}`, [{ text: 'OK' }]);
                    return false;
                }
            }
            if (typeof max == 'number') {
                if (val > max) {
                    errorPopup(`${i.name} must be less than or equal to ${max}`, [{ text: 'OK' }]);
                    return false;
                }
            }
        }
        return true;
    };

    const nextSample = () => {
        if (validate()) {
            setCurrentSample(currentSample + 1);
        }
    };

    const prevSample = () => {
        if (validate()) {
            setCurrentSample(Math.max(0, currentSample - 1));
        }
    };

    const getAverage = (indicatorId: string) => {
        const values = compact(sampleData.map((sd) => sd.values.find((v) => v.indicator.id == indicatorId)?.value));
        const total = sum(values);
        const count = values.length;
        if (count == 0) return undefined;
        return Math.round((total / count) * 10) / 10;
    };

    const saveData = () => {
        const newValuesArray = indicators.map((i) => getAverage(i.id));
        onChange(newValuesArray);
    };

    return (
        <IonContent>
            <div className="ion-padding">
                <IonProgressBar value={(currentSample - 1) / sampleCount}></IonProgressBar>
                {!completed && (
                    <>
                        <h4>
                            Sample {currentSample} of {sampleCount}
                        </h4>
                        <IonList>
                            {indicators.map((i) => (
                                <IonItem key={`${currentSample}_${i.id}`}>
                                    <IonLabel position="stacked">{i.name}</IonLabel>
                                    <IonInput
                                        type="number"
                                        step="any"
                                        min={typeof min == 'number' ? String(min) : undefined}
                                        max={typeof max == 'number' ? String(max) : undefined}
                                        value={getIndicatorValue(currentSample, i.id)}
                                        onIonChange={(e) =>
                                            setIndicatorValue(
                                                currentSample,
                                                i.id,
                                                e.detail.value ? asNumber(e.detail.value ?? '') : undefined
                                            )
                                        }
                                    />
                                </IonItem>
                            ))}
                        </IonList>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" onClick={prevSample}>
                                        <IonIcon slot="start" icon={chevronBackSharp} /> Prev
                                    </IonButton>
                                </IonCol>
                                <IonCol style={{ textAlign: 'right' }}>
                                    <IonButton expand="block" onClick={nextSample}>
                                        Next <IonIcon slot="end" icon={chevronForwardSharp} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                )}
                {completed && (
                    <div>
                        <h2>Sample Collection Completed!</h2>
                        <h3>Average of {sampleCount} Samples:</h3>
                        <IonList>
                            {indicators.map((i) => (
                                <IonItem key={i.id}>
                                    <IonLabel>{i.name}</IonLabel>
                                    <IonLabel slot="end" color="primary" style={{ textAlign: 'right' }}>
                                        {getAverage(i.id) ? formatNumber(getAverage(i.id) ?? 0, 1, '%') : ''}
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>

                        <div className="ion-padding">
                            <IonButton
                                onClick={() => {
                                    saveData();
                                }}
                                expand="block"
                                color="secondary"
                            >
                                Save Results
                            </IonButton>
                        </div>
                    </div>
                )}
            </div>
        </IonContent>
    );
}
