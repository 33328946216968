import { useEffect, useState } from 'react';
import geoLocationStore, { GeolocationInfo } from './stores/GeolocationStore';

export function useGeolocation() {
    //setup state that react will "react" to
    const [geolocation, setGeolocation] = useState<GeolocationInfo>(geoLocationStore.locationInfo);

    // Update the state on change
    useEffect(() => {
        geoLocationStore.load();
        const releaseListener = geoLocationStore.onChange.on(() => setGeolocation(geoLocationStore.locationInfo));
        return releaseListener;
    }, []);

    return geolocation;
}
