import { ProblemDetails } from '../ApiModel';
import { isProblemDetails } from './ErrorHelper';

type JsonFetchOptions = {
    headers?: Record<string, string>;
    body?: unknown;
    cancellationToken?: AbortSignal;
};

type ApiFetchOptions = {
    headers?: Record<string, string>;
    body?: BodyInit;
    cancellationToken?: AbortSignal;
    mode?: RequestMode;
};

export function networkErrorAsProblemDetails(e: Error): ProblemDetails {
    return {
        type: 'NetworkError',
        title: 'Network Error',
        detail: e.message,
        instance: e.name,
    };
}

export function jsonErrorAsProblemDetails(e: Error): ProblemDetails {
    return {
        type: 'DataError',
        title: 'Response Data Error',
        detail: "The data from your request's response could not be parsed",
        instance: e.name,
    };
}

export async function failedResponseAsProblemDetails(response: Response) {
    try {
        const respJson: ProblemDetails = await response.json();
        //if we aren't problemdetails, fall through to catch
        if (!isProblemDetails(respJson)) {
            throw new Error(JSON.stringify(respJson));
        }

        return respJson;
    } catch {
        if (response.status >= 500) {
            return {
                type: 'ServerError',
                title: 'Server Error',
                status: response.status,
                detail: response.statusText,
            };
        }

        return {
            type: 'ClientError',
            title: "We couldn't process your request",
            status: response.status,
            detail: response.statusText,
        };
    }
}

export async function readJson<T>(response: Response): Promise<T | undefined> {
    try {
        if (response.ok) {
            const contentHeader = response.headers.get('content-type');
            if (!contentHeader || contentHeader.indexOf('application/json') == -1) {
                return undefined;
            }
            const res: T = await response.json();
            return res;
        }
    } catch (e) {
        throw jsonErrorAsProblemDetails(e);
    }
    if (!response.ok) {
        const err = await failedResponseAsProblemDetails(response);
        throw err;
    }
    return undefined;
}

// All requests and responses are assumed json
export async function JsonFetch<T>(
    url: string,
    method: string,
    options: JsonFetchOptions = {}
): Promise<T | undefined> {
    const normalizedMethod = method.toUpperCase();
    const headers = { ...options.headers };
    const body = options.body === undefined ? undefined : JSON.stringify(options.body);
    if (
        (normalizedMethod == 'POST' || normalizedMethod == 'PUT' || normalizedMethod == 'PATCH') &&
        options.body !== undefined
    ) {
        headers['Content-Type'] = 'application/json';
    }

    return ApiFetch(url, method, { ...options, body: body, headers: headers });
}

// Like fetch except all responses are considered to be JSON and are encoded/decoded automatically
// and all errors are returned as ProblemDetails instances
export async function ApiFetch<T>(url: string, method: string, options: ApiFetchOptions = {}): Promise<T | undefined> {
    let response;
    const normalizedMethod = method.toUpperCase();
    try {
        const headers: Record<string, string> = { Accept: 'application/json', ...(options.headers ?? {}) };

        response = await fetch(url, {
            method: normalizedMethod,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: headers,
            body: options.body,
            signal: options.cancellationToken,
        });
    } catch (e) {
        throw networkErrorAsProblemDetails(e);
    }

    if (response.status == 204) return undefined;
    return await readJson<T>(response);
}
