import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import AfnHeader from '../../components/AfnHeader';
import LocationText from '../../components/LocationText';
import { asNumber } from '../../lib/helper/StringHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';
import { GeolocationStatus } from '../../lib/stores/GeolocationStore';
import { useGeolocation } from '../../lib/useGeolocation';

type SiteEditProps = {
    siteData: ProjectAssetSiteData;
};

type SiteValues = {
    name: string;
    lat: number | undefined;
    lng: number | undefined;
};

const SiteEdit: React.FC<SiteEditProps> = ({ siteData }) => {
    const site = siteData.site;
    const history = useHistory();
    const locationInfo = useGeolocation();

    const [siteForm, setSiteForm] = useState<SiteValues>({
        name: site.name,
        lat: site.location?.coordinates?.[1],
        lng: site.location?.coordinates?.[0],
    });

    console.log('rendering with siteForm.name', siteForm.name);
    const updateWithCurrentLocation = () => {
        setSiteForm({
            ...siteForm,
            lat: locationInfo.lat,
            lng: locationInfo.lng,
        });
    };

    const doSave = () => {
        siteData.updateSiteNameAndLocation(siteForm.name, siteForm.lat, siteForm.lng);
        history.goBack();
    };

    return (
        <IonPage>
            <AfnHeader showBackButton={true}>Edit Site</AfnHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="stacked">Site Name</IonLabel>
                    <IonInput
                        type="text"
                        value={siteForm.name}
                        onIonChange={(e) => {
                            console.log('got change', e.detail.value, 'from', siteForm.name);
                            setSiteForm({ ...siteForm, name: e.detail.value ?? '' });
                        }}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Latitude</IonLabel>
                    <IonInput
                        type="number"
                        step="any"
                        value={siteForm.lat}
                        onIonChange={(e) => setSiteForm({ ...siteForm, lat: asNumber(e.detail.value as string) })}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Longitude</IonLabel>
                    <IonInput
                        type="number"
                        step="any"
                        value={siteForm.lng}
                        onIonChange={(e) => setSiteForm({ ...siteForm, lng: asNumber(e.detail.value as string) })}
                    />
                </IonItem>
                <IonItem>
                    <div>
                        Current Device Location: <LocationText loc={locationInfo} />
                    </div>
                    {locationInfo.status == GeolocationStatus.OK && (
                        <IonButton onClick={updateWithCurrentLocation}>Use this location</IonButton>
                    )}
                </IonItem>
                <div className="ion-padding">
                    <IonButton expand="block" onClick={() => doSave()}>
                        Save
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SiteEdit;
