import { EventEmitter } from '../helper/EventHelper';
import storage from '../Storage';
import { AttachmentUpdate } from '../SyncHelper';

export default class AttachmentUpdatesStore {
    onChange = new EventEmitter<AttachmentUpdatesStore>();
    updates: AttachmentUpdate[] = [];
    projectId = '';

    async loadForProject(projectId: string): Promise<AttachmentUpdate[]> {
        this.projectId = projectId;
        this.updates = await storage.getAttachmentUpdates(projectId);
        this.onChange.fire(this);
        return this.updates;
    }

    async addUpdate(update: AttachmentUpdate) {
        // remove or update any existing updates for the same value
        const existingIdx = this.updates.findIndex((u) => u.attachmentId == update.attachmentId);

        if (existingIdx >= 0) {
            const existingUpdate = this.updates[existingIdx];
            if (update.updateKind == 'replace' && existingUpdate.updateKind == 'replace') {
                this.updates.splice(existingIdx, 1, update);
            } else if (update.updateKind == 'replace' && existingUpdate.updateKind == 'create') {
                existingUpdate.localpath = update.localpath;
                existingUpdate.mediaType = update.mediaType;
                existingUpdate.filename = update.filename;
            } else if (update.updateKind == 'delete') {
                this.updates.splice(existingIdx, 1, update);
            } else if (existingUpdate.updateKind == 'delete') {
                throw new Error('trying to update or create a deleted attachmentID');
            } else if (update.updateKind == 'create') {
                throw new Error('duplicate create');
            }
        } else {
            // add our new update to the end
            this.updates.push(update);
        }
        this.onChange.fire(this);
        await this.save();
    }

    save() {
        return storage.setAttachmentUpdates(this.projectId, this.updates);
    }
}
