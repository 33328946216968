import React from 'react';
import { markdownToHtml } from '../lib/helper/MarkdownHelper';

import './markdown.css';

function Markdown(props: { source?: string }) {
    return <div className="markdown" dangerouslySetInnerHTML={{ __html: markdownToHtml(props.source) }} />;
}

export default React.memo(Markdown);
