import { IonButton } from '@ionic/react';
import { formatNumber } from '../lib/helper/StringHelper';
import geoLocationStore, { GeolocationInfo, GeolocationStatus } from '../lib/stores/GeolocationStore';

export default function LocationText({ loc: locationInfo }: { loc: GeolocationInfo }) {
    return (
        <>
            {locationInfo.status == GeolocationStatus.OK && (
                <>
                    {formatNumber(locationInfo.lat, 5)} , {formatNumber(locationInfo.lng, 5)}
                </>
            )}
            {locationInfo.status == GeolocationStatus.LOADING && <>Loading...</>}
            {locationInfo.status == GeolocationStatus.ERROR && (
                <>
                    Not Available{' '}
                    <IonButton size="small" onClick={() => geoLocationStore.load()}>
                        Retry
                    </IonButton>
                </>
            )}
            {locationInfo.status == GeolocationStatus.NOTSUPPORTED && <>Not Supported</>}
            {locationInfo.status == GeolocationStatus.NOPERMISSION && (
                <>
                    No Permission{' '}
                    <IonButton size="small" onClick={() => geoLocationStore.load()}>
                        Retry
                    </IonButton>
                </>
            )}
        </>
    );
}
