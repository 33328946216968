import { ProblemDetails, ValidationProblemDetails } from '../ApiModel';
import { titleCase } from './StringHelper';

function errorKeys<T>(errors: ValidationErrors<T> | undefined): (keyof ValidationErrors<T>)[] {
    return Object.keys(errors ?? {}) as (keyof ValidationErrors<T>)[];
}

export function isUnknownObject(x: unknown): x is { [key in PropertyKey]: unknown } {
    return x !== null && typeof x === 'object';
}

export function isProblemDetails(e: unknown): e is ProblemDetails {
    return isUnknownObject(e) && typeof e.type == 'string' && typeof e.title == 'string';
}

export function isValidationProblemDetails(e: unknown): e is ValidationProblemDetails {
    return isProblemDetails(e) && !!(e as { errors?: ValidationErrors }).errors;
}

export type ValidationErrors<T = Record<string, string>> = { [K in DeepKeyOf<T>]?: string[] };

export function getErrorsFor<T = Record<string, string>>(
    errors: ValidationErrors<T> | undefined,
    field: keyof ValidationErrors<T>
): string[] | undefined {
    return errors && errors[field];
}

export function getErrorsForFieldAndImmediateChildren<T = Record<string, string>>(
    errors: ValidationErrors<T> | undefined,
    field: keyof ValidationErrors<T>,
    nameMap: (n: string) => string | undefined = () => undefined
): string[] {
    const children = errors ? mergeFieldNamesWithMessages(getChildErrors(errors, field, false), nameMap) : [];
    const fieldErr = getErrorsFor(errors, field);

    return children.concat(fieldErr ?? []);
}

export function mergeFieldNamesWithMessages(
    errors: ValidationErrors,
    nameMap: (n: string) => string | undefined
): string[] {
    const res: string[] = [];
    for (const k of Object.keys(errors ?? {})) {
        const fieldLabel = nameMap(k) ?? titleCase(k.replace(/\[(\d+)\]/g, '$1').replace('.', ' '));
        res.push(`${fieldLabel} - ${errors[k]}`);
    }
    return res;
}

export function hasError<T = Record<string, string>>(
    errors: ValidationErrors<T> | undefined,
    field: keyof ValidationErrors<T>
): boolean {
    return !!getErrorsFor(errors, field);
}

export function hasErrors<T = Record<string, string>>(errors: ValidationErrors<T> | undefined): boolean {
    return errorKeys(errors).length > 0;
}

export function errorsStartingWith<T = Record<string, string>>(
    errors: ValidationErrors<T> | undefined,
    prefix: string
): ValidationErrors<T> {
    const subset: ValidationErrors = {};
    for (const k of errorKeys(errors)) {
        if ((k as string).startsWith(prefix)) {
            subset[k] = errors?.[k];
        }
    }
    return subset;
}

export function getChildErrors<T = Record<string, string>>(
    errors: ValidationErrors<T>,
    field: keyof ValidationErrors<T>,
    deep = true
): ValidationErrors {
    const childErrors: ValidationErrors = {};
    const objectPrefix = `${field}.`;
    const arrayPrefix = `${field}[`;
    for (const k of errorKeys(errors)) {
        if ((k as string).startsWith(objectPrefix)) {
            const newKey = (k as string).substring(objectPrefix.length);
            if (!deep && (newKey.includes('[') || newKey.includes('.'))) continue;
            childErrors[newKey] = errors[k];
        }
        if ((k as string).startsWith(arrayPrefix)) {
            const newKey = (k as string).substring(arrayPrefix.length - 1);
            if (!deep && newKey.indexOf(']') == newKey.length - 1) continue;
            childErrors[newKey] = errors[k];
        }
    }
    return childErrors;
}

export function hasChildErrors<T = Record<string, string>>(
    errors: ValidationErrors<T>,
    field: keyof ValidationErrors<T>
): boolean {
    return Object.keys(getChildErrors(errors, field)).length > 0;
}

export function removeError<T = Record<string, string>>(
    errors: ValidationErrors<T>,
    field: keyof ValidationErrors<T>
): ValidationErrors<T> {
    const newErrors: ValidationErrors = {};
    for (const key of errorKeys(errors)) {
        if (key !== field) {
            newErrors[key] = errors[key];
        }
    }
    return newErrors;
}

export function removeChildErrors<T = Record<string, string>>(
    errors: ValidationErrors<T>,
    field: keyof ValidationErrors<T>
): ValidationErrors<T> {
    const newErrors: ValidationErrors<T> = {};
    for (const key of errorKeys(errors)) {
        if (!(key as string).startsWith(field + '.') && !(key as string).startsWith(field + '[')) {
            newErrors[key] = errors[key];
        }
    }
    return newErrors;
}

export function erroredFieldCount(errors: ValidationErrors): number {
    let count = 0;
    Object.keys(errors ?? {}).forEach((k) => (count = count + (errors[k] ? 1 : 0)));
    return count;
}

export function buildValidationErrorDetails(errors: ValidationErrors) {
    return {
        type: 'ValidationError',
        detail: 'There were validation errors',
        status: 400,
        title: 'Validation Error',
        errors: errors,
    } as ValidationProblemDetails;
}
