import { useEffect, useRef, useState } from 'react';
import { ProjectSummary } from './ApiModel';
import ProjectsStore from './stores/ProjectsStore';

export function useProjects() {
    const projectsStore = useRef(new ProjectsStore()).current;
    //setup state that react will "react" to
    const [projects, setProjects] = useState<ProjectSummary[]>(projectsStore.projects);

    // Update the state on change
    useEffect(() => {
        projectsStore.load();
        const releaseListener = projectsStore.onChange.on(() => setProjects(projectsStore.projects));
        return releaseListener;
    }, []);

    return projects;
}

export function useProject(projectId: string) {
    //setup state that react will "react" to
    const projectsStore = useRef(new ProjectsStore()).current;
    const [project, setProject] = useState<ProjectSummary | undefined>(projectsStore.getById(projectId));

    // Update the state on change
    useEffect(() => {
        projectsStore.load();
        const releaseListener = projectsStore.onChange.on(() => setProject(projectsStore.getById(projectId)));
        return releaseListener;
    }, []);

    return project;
}
