import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonText } from '@ionic/react';
import { useHistory, useRouteMatch } from 'react-router';
import AfnHeader from '../../components/AfnHeader';
import { distinct } from '../../lib/helper/ArrayHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';
import { SupportedMeasurementTypes } from './CollectUsingStrategy';
import { checkmarkSharp } from 'ionicons/icons';

type SiteHomeProps = {
    siteData: ProjectAssetSiteData;
};

const SiteHome: React.FC<SiteHomeProps> = ({ siteData }) => {
    const currentRoute = useRouteMatch();
    const history = useHistory();

    const sitePhotos = siteData.sitePhotos;
    const measurementStrategies = distinct(
        siteData.siteIndicators.map((i) => i.measurementStrategy),
        (ms) => ms.id
    ).filter((ms) => SupportedMeasurementTypes.includes(ms.measurementType));

    const sitePhotosComplete = siteData.sitePhotoCollectionComplete;

    return (
        <IonPage>
            <AfnHeader
                showBackButton={true}
                subHeading={
                    'AU: ' + siteData.assessmentUnit.name + ' \u00a0\u00a0\u00a0\u00a0Site: ' + siteData.site.name
                }
            >
                Collect Data
            </AfnHeader>
            <IonContent>
                <IonList>
                    <IonItem
                        detail={true}
                        button={true}
                        onClick={() => history.push({ pathname: `${currentRoute.url}/siteedit` })}
                    >
                        <IonLabel className="ion-text-wrap">
                            <IonText color="primary">
                                <h2>Site Name and Location</h2>
                            </IonText>
                        </IonLabel>
                    </IonItem>
                    {sitePhotos.length > 0 && (
                        <IonItem
                            detail={true}
                            button={true}
                            onClick={() => history.push({ pathname: `${currentRoute.url}/sitephotos` })}
                        >
                            <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                    <h2>
                                        {sitePhotosComplete && (
                                            <IonIcon
                                                color="success"
                                                style={{ marginRight: '0.5rem' }}
                                                icon={checkmarkSharp}
                                            />
                                        )}
                                        Site Photos
                                    </h2>
                                </IonText>
                                <IonText color="medium">
                                    <p style={{ fontSize: '0.8rem' }}>{sitePhotos.length} Required Images</p>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                    )}

                    {measurementStrategies.map((strat) => (
                        <IonItem
                            key={strat.id}
                            detail={true}
                            button={true}
                            onClick={() => history.push({ pathname: `${currentRoute.url}/strategy/${strat.id}` })}
                        >
                            <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                    <h2>
                                        {siteData.strategyCollectionComplete(strat.id) && (
                                            <IonIcon
                                                color="success"
                                                style={{ marginRight: '0.5rem' }}
                                                icon={checkmarkSharp}
                                            />
                                        )}
                                        {strat.name}
                                    </h2>
                                </IonText>
                                <IonText color="medium">
                                    <p style={{ fontSize: '0.8rem' }}>
                                        {siteData
                                            .indicatorsForStrategy(strat.id)
                                            .map((ind) => ind.name)
                                            .join(', ')}
                                    </p>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SiteHome;
