import produce from 'immer';
import { EventEmitter } from '../helper/EventHelper';
import storage from '../Storage';
import { SiteUpdate } from '../SyncHelper';

export default class SiteUpdatesStore {
    onChange = new EventEmitter<SiteUpdatesStore>();
    updates: SiteUpdate[] = [];
    projectId = '';

    async loadForProject(projectId: string): Promise<SiteUpdate[]> {
        this.projectId = projectId;
        this.updates = await storage.getSiteUpdates(projectId);
        this.onChange.fire(this);
        return this.updates;
    }

    addUpdate(update: SiteUpdate) {
        // find any existing update for the site
        const newUpdates = produce(this.updates, (updates) => {
            const existing = updates.find((u) => u.site.id == update.site.id);
            if (existing) {
                switch (update.updateKind) {
                    case 'location':
                        existing.site.location = update.site.location;
                        if (existing.updateKind == 'name') existing.updateKind = 'nameAndLocation';
                        break;

                    case 'name':
                        existing.site.name = update.site.name;
                        if (existing.updateKind == 'location') existing.updateKind = 'nameAndLocation';
                        break;

                    case 'nameAndLocation':
                        existing.site.location = update.site.location;
                        existing.site.name = update.site.name;
                        if (existing.updateKind == 'name' || existing.updateKind == 'location')
                            existing.updateKind = 'nameAndLocation';
                        break;

                    case 'create':
                        throw new Error('Cannot create site, Site with same Id already exists');

                    default:
                        throw new Error('Unknown Update Kind');
                }
            } else {
                updates.push(update);
            }
        });

        this.updates = newUpdates;
        this.onChange.fire(this);
        this.save();
    }

    save() {
        return storage.setSiteUpdates(this.projectId, this.updates);
    }
}
