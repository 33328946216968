import { EventEmitter } from '../helper/EventHelper';

export enum GeolocationStatus {
    LOADING,
    OK,
    NOPERMISSION,
    NOTSUPPORTED,
    ERROR,
}

export type GeolocationInfo = {
    status: GeolocationStatus;
    lat: number;
    lng: number;
};

class GeolocationStore {
    onChange = new EventEmitter<GeolocationStore>();
    locationInfo: GeolocationInfo = {
        status: GeolocationStatus.LOADING,
        lat: 0,
        lng: 0,
    };

    setLocationInfo(status: GeolocationStatus, lat?: number, lng?: number) {
        this.locationInfo = {
            status: status,
            lat: lat ?? 0,
            lng: lng ?? 0,
        };
        this.onChange.fire(this);
        return this.locationInfo;
    }

    async load(): Promise<GeolocationInfo> {
        if (!navigator.geolocation) {
            return this.setLocationInfo(GeolocationStatus.NOTSUPPORTED);
        }
        try {
            const loc = await new Promise<GeolocationPosition>((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    (s) => resolve(s),
                    (e) => reject(e),
                    { enableHighAccuracy: true, maximumAge: 1 * 1000 }
                )
            );
            return this.setLocationInfo(GeolocationStatus.OK, loc.coords.latitude, loc.coords.longitude);
        } catch (e) {
            return this.setLocationInfo(
                (e as GeolocationPositionError).PERMISSION_DENIED
                    ? GeolocationStatus.NOPERMISSION
                    : GeolocationStatus.ERROR
            );
        }
    }
}

const GeoLocationSingleton = new GeolocationStore();

export default GeoLocationSingleton;
