import { useMemo } from 'react';
import ProjectAssetData from './ProjectAssetData';
import { useProjectAsset } from './useAssets';
import { useMostRecentAssetYear } from './useAssetYear';
import { useIndicators } from './useIndicators';
import { useProject } from './useProjects';

export function useProjectAssetData(projectId: string, assetId: string, siteId: string): ProjectAssetData | undefined {
    const project = useProject(projectId);
    const [asset, assetStore] = useProjectAsset(projectId, assetId);
    const [assetYear, assetYearStore] = useMostRecentAssetYear(projectId, assetId);
    const indicators = useIndicators();

    const siteData = useMemo(
        () =>
            !project || !asset || !assetYear || indicators.length == 0
                ? undefined
                : new ProjectAssetData(project, asset, assetYear, indicators, siteId, assetStore, assetYearStore),
        [project, asset, assetYear, indicators, siteId]
    );

    return siteData;
}
