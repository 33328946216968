import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import {} from '@ionic/react';
import { handleRedirectCallback, initializeAuth as initAuth, onLoginStateChanged } from './auth/auth';

export async function initializeAuth() {
    const redirectNavigate = (url: string) => {
        Capacitor.isNativePlatform()
            ? Browser.open({
                  url: url,
              })
            : window.location.assign(url);
        return false;
    };

    const baseUrl = Capacitor.isNativePlatform()
        ? 'afn://'
        : `${document.location.protocol}//${document.location.host}/`;

    initAuth({
        clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
        redirectUrl: `${baseUrl}auth`,
        postLogoutRedirectUrl: `${baseUrl}/loggedout`,
        apiScope: `${process.env.REACT_APP_AUTH_API_SCOPE}`,
        tenantName: `${process.env.REACT_APP_AUTH_API_TENANT}`,
        onRedirectNavigate: redirectNavigate,
    });

    App.addListener('appUrlOpen', (data) => {
        console.log('App url opened' + JSON.stringify(data));
        const hash = data.url.split('#', 2)[1];
        handleRedirectCallback('#' + hash);
        if (data.url.startsWith('afn://auth/loggedout')) {
            onLoginStateChanged.fire();
            window.location.assign('/tab3');
        }
    });

    await handleRedirectCallback(window.location.hash);
}
