import { EventEmitter } from '../helper/EventHelper';
import { ProjectAsset } from '../ApiModel';
import storage from '../Storage';
import { SiteUpdate } from '../SyncHelper';
import SiteUpdatesStore from './SiteUpdatesStore';
import produce from 'immer';

export default class AssetStore {
    onChange = new EventEmitter<ProjectAsset | undefined>();
    private asset: ProjectAsset | undefined;

    siteUpdateStore = new SiteUpdatesStore();

    async loadForProjectAndId(projectId: string, assetId: string): Promise<ProjectAsset | undefined> {
        const assets = await storage.getAssets(projectId);
        await this.siteUpdateStore.loadForProject(projectId);
        this.asset = assets.find((a) => a.id == assetId);
        this.onChange.fire(this.asset);
        return this.asset;
    }

    async updateSite(siteUpdate: SiteUpdate) {
        if (!this.asset) return;

        this.siteUpdateStore.addUpdate(siteUpdate);

        const newAsset = produce(this.asset, (asset) => {
            const assessmentUnit = asset.subAssets
                .flatMap((sa) => sa.assessmentUnits)
                .find((au) => au.id == siteUpdate.site.assessmentUnitId);

            if (assessmentUnit) {
                if (siteUpdate.updateKind == 'create') {
                    assessmentUnit.sites.push(siteUpdate.site);
                } else {
                    const existingSite = assessmentUnit.sites.find((s) => s.id == siteUpdate.site.id);
                    if (existingSite) {
                        if (siteUpdate.updateKind == 'location' || siteUpdate.updateKind == 'nameAndLocation') {
                            existingSite.location = siteUpdate.site.location;
                        }
                        if (siteUpdate.updateKind == 'name' || siteUpdate.updateKind == 'nameAndLocation') {
                            existingSite.name = siteUpdate.site.name;
                        }
                    }
                }
            }
        });
        this.asset = newAsset;
        this.save();
        this.onChange.fire(this.asset);
    }

    async save() {
        if (!this.asset) return;
        const assets = await storage.getAssets(this.asset.projectID);
        const existingIdx = assets.findIndex((a) => a.id == this.asset?.id);
        if (existingIdx >= 0) {
            assets[existingIdx] = this.asset;
        }
        storage.setAssets(this.asset.projectID, assets);
    }
}
