import { Remarkable } from 'remarkable';

const markdownEngine = new Remarkable('full', {
    html: true,
    linkify: true,
    linkTarget: '_blank',
    typographer: true,
});

export function markdownToHtml(md?: string): string {
    if (!md) return '';
    return markdownEngine.render(md);
}
