import { footstepsSharp, flaskSharp } from 'ionicons/icons';
import ModalToggleButton from '../../../components/ModalToggleButton';
import { Indicator, MeasurementStrategy } from '../../../lib/ApiModel';
import SampleAverageCalculator from './SampleAverageCalculator';
import StepCountCalculator from './StepCountCalculator';

export interface CalculatorProps {
    indicators: Indicator[];
    onChange: (newValues: (number | undefined)[]) => void;
}

type CalculatorButtonProps = CalculatorProps & {
    measurementStrategy: MeasurementStrategy;
};

function closeAfterChange<U, T extends U[]>(changeFunc: (...args: T) => void, closeFunc: () => void) {
    return (...args: T) => {
        changeFunc(...args);
        closeFunc();
    };
}

export default function CalculatorButton({ measurementStrategy, onChange, indicators }: CalculatorButtonProps) {
    switch (measurementStrategy.measurementType) {
        case 'StepCount':
            return (
                <ModalToggleButton label="Start Step Count" icon={footstepsSharp}>
                    {(onClose) => (
                        <StepCountCalculator indicators={indicators} onChange={closeAfterChange(onChange, onClose)} />
                    )}
                </ModalToggleButton>
            );

        case 'AverageOf2':
            return (
                <ModalToggleButton label="Average 2 Samples" icon={flaskSharp}>
                    {(onClose) => (
                        <SampleAverageCalculator
                            max={measurementStrategy.maximum}
                            min={measurementStrategy.minimum}
                            sampleCount={2}
                            indicators={indicators}
                            onChange={closeAfterChange(onChange, onClose)}
                        />
                    )}
                </ModalToggleButton>
            );

        case 'AverageOf5':
            return (
                <ModalToggleButton label="Average 5 Samples" icon={flaskSharp}>
                    {(onClose) => (
                        <SampleAverageCalculator
                            max={measurementStrategy.maximum}
                            min={measurementStrategy.minimum}
                            sampleCount={5}
                            indicators={indicators}
                            onChange={closeAfterChange(onChange, onClose)}
                        />
                    )}
                </ModalToggleButton>
            );

        default:
            return null;
    }
}
