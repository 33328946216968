export class CancellablePromise<T> {
    abortController: AbortController;
    result: Promise<T>;

    constructor(makeRequest: (signal: AbortSignal) => Promise<T>) {
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        this.result = makeRequest(this.abortController.signal).then((v) => {
            if (signal.aborted) throw new DOMException('Request cancelled', 'AbortError');
            return v;
        });
    }

    static From<T>(prom: Promise<T>): CancellablePromise<T> {
        return new CancellablePromise(() => prom);
    }

    cancel() {
        return this.abortController.abort();
    }
}

export class Deferred<T> {
    promise: Promise<T>;
    reject!: (e: unknown) => unknown;
    resolve!: (value: T) => unknown;
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

export class HotSwappablePromise<T> {
    deferred: Deferred<T>;
    current?: CancellablePromise<T>;

    constructor() {
        this.deferred = new Deferred();
    }

    get result(): Promise<T> {
        return this.deferred.promise;
    }

    swap(newPromise: CancellablePromise<T>) {
        const oldPromise = this.current;

        this.current = newPromise;
        this.current.result.then(
            (result) => {
                if (this.current == newPromise) {
                    this.deferred.resolve(result);
                }
            },
            (error) => {
                if (this.current == newPromise) {
                    this.deferred.reject(error);
                }
            }
        );

        oldPromise?.cancel();
        return this.deferred.promise;
    }
}
