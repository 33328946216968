import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonPage,
    IonText,
} from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import AfnHeader from '../components/AfnHeader';
import { useProjectAsset } from '../lib/useAssets';
import { orderBy } from '../lib/helper/ArrayHelper';
import { useGeolocation } from '../lib/useGeolocation';
import { GeolocationStatus } from '../lib/stores/GeolocationStore';
import { Site } from '../lib/ApiModel';
import { formatNumber } from '../lib/helper/StringHelper';
import LocationText from '../components/LocationText';
import ProjectAssetSiteData from '../lib/ProjectAssetData';
import { useProject } from '../lib/useProjects';
import { useMostRecentAssetYear } from '../lib/useAssetYear';
import { useIndicators } from '../lib/useIndicators';
import { checkmarkSharp } from 'ionicons/icons';

const dist = (lat1: number, lng1: number, lat2: number, lng2: number) => {
    const p = Math.PI / 180;
    return (
        Math.acos(
            Math.sin(lat1 * p) * Math.sin(lat2 * p) +
                Math.cos(lat1 * p) * Math.cos(lat2 * p) * Math.cos((lng2 - lng1) * p)
        ) * 6371
    );
};

const SiteManage: React.FC = () => {
    const history = useHistory();
    const { projectid, assetid } = useParams<{ projectid: string; assetid: string }>();
    const project = useProject(projectid);
    const [asset, assetStore] = useProjectAsset(projectid, assetid);
    const [assetYear, assetYearStore] = useMostRecentAssetYear(projectid, assetid);
    const indicators = useIndicators();

    const assessmentUnits = asset?.subAssets.flatMap((sa) => sa.assessmentUnits) ?? [];

    // TODO: Add a new site button, that uses the AssetStore.updateSite(siteUpdate: SiteUpdate) method to add a new site to the saved data.

    const locationInfo = useGeolocation();

    const closestSites =
        locationInfo.status != GeolocationStatus.OK
            ? []
            : orderBy(
                  assessmentUnits
                      .flatMap((au) => au.sites.map((s) => ({ site: s, unit: au })))
                      .filter((s) => s.site.location?.coordinates && s.site.location.coordinates.length),
                  (s) =>
                      s.site.location
                          ? dist(
                                s.site.location.coordinates[1],
                                s.site.location.coordinates[0],
                                locationInfo.lat,
                                locationInfo.lng
                            )
                          : 20000,
                  'asc'
              );

    const renderSite = (site: Site, assessmentUnitPrefix?: string) => {
        if (!project || !asset || !assetYear) return;

        const siteData = new ProjectAssetSiteData(
            project,
            asset,
            assetYear,
            indicators,
            site.id,
            assetStore,
            assetYearStore
        );

        const isComplete = siteData.siteMeasurementCollectionComplete && siteData.sitePhotoCollectionComplete;

        return (
            <IonItem
                key={site.id}
                detail={true}
                button={true}
                onClick={() =>
                    history.push({
                        pathname: `/projects/${asset?.projectID}/assets/${asset?.id}/sites/${site.id}`,
                    })
                }
            >
                <IonLabel className="ion-text-wrap">
                    {isComplete && <IonIcon color="success" style={{ marginRight: '0.5rem' }} icon={checkmarkSharp} />}
                    <IonText>
                        {assessmentUnitPrefix}
                        {site.name}
                    </IonText>
                </IonLabel>
                {locationInfo.status == GeolocationStatus.OK && site.location && (
                    <IonText slot="end">
                        <small>
                            {formatNumber(
                                dist(
                                    site.location.coordinates[1],
                                    site.location.coordinates[0],
                                    locationInfo.lat,
                                    locationInfo.lng
                                ),
                                2,
                                'km'
                            )}
                        </small>
                    </IonText>
                )}
            </IonItem>
        );
    };

    return (
        <IonPage>
            <AfnHeader>Asset: {asset?.assetType.name}</AfnHeader>
            <IonContent>
                <p className="ion-padding-start"> Select your current sample site</p>
                <IonLabel style={{ margin: '1rem' }}>
                    Location (lat,lng): &nbsp;
                    <LocationText loc={locationInfo} />
                </IonLabel>
                <IonList>
                    {closestSites.length > 0 && (
                        <IonItemGroup>
                            <IonItemDivider color="tertiary">Closest Sites</IonItemDivider>
                            <IonList>
                                {closestSites.slice(0, 4).map((s) => renderSite(s.site, `AU ${s.unit.name} - `))}
                            </IonList>
                        </IonItemGroup>
                    )}

                    {assessmentUnits.map((assessmentUnit) => (
                        <IonItemGroup key={assessmentUnit.id}>
                            <IonItemDivider key={assessmentUnit.id} color="tertiary">
                                AU: {assessmentUnit.name}{' '}
                                <small style={{ marginLeft: '0.5rem' }}>
                                    {assessmentUnit.sites.length < (assessmentUnit.minimumSites ?? 0) &&
                                        `(${assessmentUnit.sites.length} of ${assessmentUnit.minimumSites ?? '0'})`}
                                </small>
                            </IonItemDivider>
                            {orderBy(assessmentUnit.sites, (as) => as.name, 'asc').map((site) => renderSite(site))}
                            {assessmentUnit.sites.length == 0 && (
                                <IonItem detail={false} button={false}>
                                    <IonLabel className="ion-text-wrap">
                                        <IonText color="medium">No Sites Established</IonText>
                                    </IonLabel>
                                </IonItem>
                            )}
                        </IonItemGroup>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SiteManage;
