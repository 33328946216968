import { useEffect, useRef, useState } from 'react';
import { AssetYear } from './ApiModel';
import AssetYearStore from './stores/AssetYearStore';

export function useMostRecentAssetYear(projectId: string, assetId: string): [AssetYear | undefined, AssetYearStore] {
    const assetYearStoreRef = useRef(new AssetYearStore());
    const assetYearStore = assetYearStoreRef.current;
    //setup state that react will "react" to
    const [assetYear, setAssetYear] = useState<AssetYear | undefined>(assetYearStore.assetYear);

    // Update the state on change
    useEffect(() => {
        const releaseListener = assetYearStore.onChange.on((ay) => setAssetYear(ay));
        assetYearStore.loadMostRecentForAsset(projectId, assetId);
        return releaseListener;
    }, []);

    return [assetYear, assetYearStore];
}
