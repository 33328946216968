import { IonContent, IonPage, IonLabel, IonItem, IonList, IonText, IonListHeader } from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';
import { useProject } from '../lib/useProjects';
import AfnHeader from '../components/AfnHeader';
import { useProjectAssets } from '../lib/useAssets';

const AssetList: React.FC = () => {
    const { projectid } = useParams<{ projectid: string }>();
    const project = useProject(projectid);
    const projectAssets = useProjectAssets(projectid);

    const history = useHistory();

    return (
        <IonPage>
            <AfnHeader>Project: {project?.name}</AfnHeader>
            <IonContent>
                <IonList>
                    <IonListHeader>Select the asset for data collection</IonListHeader>
                    {projectAssets?.map((asset) => (
                        <IonItem
                            key={asset.id}
                            detail={true}
                            button={true}
                            onClick={() =>
                                history.push({ pathname: `/projects/${asset.projectID}/assets/${asset.id}` })
                            }
                        >
                            <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                    <h1>{asset.assetType.name}</h1>
                                </IonText>
                                <IonText>
                                    <p>
                                        {asset.subAssets.flatMap((sa) => sa.assessmentUnits).length} Assessment Units
                                        and{' '}
                                        {
                                            asset.subAssets
                                                .flatMap((sa) => sa.assessmentUnits)
                                                .flatMap((au) => au.sites).length
                                        }{' '}
                                        sites
                                    </p>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default AssetList;
