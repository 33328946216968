import { Redirect, Route, Switch } from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupConfig,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { earthOutline, syncCircleOutline } from 'ionicons/icons';

import ProjectList from './pages/ProjectList';
import SiteCollectionRouter from './pages/SiteCollection/SiteCollectionRouter';
import AssetList from './pages/AssetList';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* AfN Styles */
import './App.css';
import SyncPage from './pages/Sync';
import SiteManage from './pages/SiteManage';

setupConfig({
    mode: 'md', //Force a mode to keep the app experience consistent across platforms where possible
});

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Switch>
                            <Route exact path="/projects">
                                <ProjectList />
                            </Route>
                            <Route exact path="/sync">
                                <SyncPage />
                            </Route>
                            <Route exact path="/projects/:projectid">
                                <AssetList />
                            </Route>
                            <Route exact path="/projects/:projectid/assets/:assetid">
                                <SiteManage />
                            </Route>
                            <Route path="/projects/:projectid/assets/:assetid/sites/:siteid">
                                <SiteCollectionRouter />
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/projects" />
                            </Route>
                            <Route path="*">
                                <Redirect to="/projects" />
                            </Route>
                        </Switch>
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom" color="secondary">
                        <IonTabButton tab="projects" href="/projects">
                            <IonIcon icon={earthOutline} />
                            <IonLabel>Projects</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="sync" href="/sync">
                            <IonIcon icon={syncCircleOutline} />
                            <IonLabel>Sync</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
