import { EventEmitter } from '../helper/EventHelper';
import { ProjectAsset } from '../ApiModel';
import storage from '../Storage';

export default class AssetsStore {
    onChange = new EventEmitter<AssetsStore>();
    assets: ProjectAsset[] = [];

    async loadForProject(projectId: string): Promise<ProjectAsset[]> {
        this.assets = await storage.getAssets(projectId);
        this.onChange.fire(this);
        return this.assets;
    }

    getByAssetId(assetId: string) {
        return this.assets.find((a) => a.id == assetId);
    }
}
