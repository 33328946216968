import { useState, useEffect, useRef } from 'react';
import { Indicator } from './ApiModel';
import IndicatorsStore from './stores/IndicatorsStore';

export function useIndicators() {
    const indicatorStore = useRef(new IndicatorsStore()).current;

    const [indicators, setIndicators] = useState<Indicator[]>([]);

    // Update the state on change
    useEffect(() => {
        const releaseListener = indicatorStore.onChange.on(() => setIndicators(indicatorStore.indicators));
        indicatorStore.load();
        return releaseListener;
    }, []);

    return indicators ?? [];
}

export interface AfnPhoto {
    filepath: string;
    webviewPath?: string;
    latitude?: string;
    longitude?: string;
    timestamp: number;
}
