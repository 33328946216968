import { IApiClient } from './ApiClient';
import {
    AssetYear,
    Attachment,
    AttachmentType,
    Indicator,
    ProjectAsset,
    ProjectSummary,
    Site,
    UpdateIndicatorDataMeasurement,
} from './ApiModel';
import { getApiClient } from './getApiClient';
import { distinct, orderBy } from './helper/ArrayHelper';
import { readFileToBlob } from './helper/AttachmentHelper';
import storage from './Storage';
import AttachmentCacheStore from './stores/AttachmentCacheStore';

export type MeasurementUpdate = {
    projectId: string;
    assetId: string;
    year: number;
    assessmentUnitId: string;
    indicatorId: string;
    siteId: string;
    update: UpdateIndicatorDataMeasurement;
};

export type SiteUpdate = {
    projectId: string;
    assetId: string;
    site: Site;
    updateKind: 'name' | 'location' | 'nameAndLocation' | 'create';
};

export type AttachmentCreateUpdate = {
    updateKind: 'create';
    projectId: string;
    assetId: string;
    year: number;
    assessmentUnitId: string;
    siteId: string;
    indicatorId?: string;
    attachmentType: AttachmentType;
    filename?: string;
    localpath: string;
    attachmentId: string;
    mediaType: string;
};

export type AttachmentReplaceUpdate = {
    updateKind: 'replace';
    projectId: string;
    assetId: string;
    year: number;
    assessmentUnitId: string;
    siteId: string;
    indicatorId?: string;
    filename?: string;
    localpath: string;
    attachmentId: string;
    mediaType: string;
};

export type AttachmentDeleteUpdate = {
    updateKind: 'delete';
    projectId: string;
    assetId: string;
    year: number;
    assessmentUnitId: string;
    siteId: string;
    indicatorId?: string;
    attachmentId: string;
};

export type AttachmentUpdate = AttachmentCreateUpdate | AttachmentReplaceUpdate | AttachmentDeleteUpdate;

async function getIndicators(client: IApiClient, projectId: string): Promise<Indicator[]> {
    return await client.getIndicatorsByProject(projectId);
}

async function getWritableProjects(client: IApiClient): Promise<ProjectSummary[]> {
    const projects = await client.getProjectsForCurrentUser();
    // we are only interested in project in which we have write access, and which are registered
    return projects.filter((p) => p.level == 'Edit' || p.level == 'Full').filter((p) => p.registrationDate);
}

async function getAssetsForProject(client: IApiClient, project: ProjectSummary): Promise<ProjectAsset[]> {
    return await Promise.all(project.projectAssets.map((a) => client.getProjectAsset(project.id, a.id)));
}

async function getAssetYearsForProject(client: IApiClient, project: ProjectSummary): Promise<AssetYear[]> {
    return await client.getAssetYears(project.id);
}

async function clearLocalAssetData(projectId: string, assetId: string) {
    await storage.setAssetYears(projectId, assetId, []);
}

async function clearAttachmentCache(projectId: string) {
    const attachmentCacheStore = new AttachmentCacheStore();
    await attachmentCacheStore.loadForProject(projectId);
    await attachmentCacheStore.removeAllEntries();
}

async function clearLocalProjectData(projectId: string) {
    const assets = await storage.getAssets(projectId);
    for (const asset of assets) {
        await clearLocalAssetData(projectId, asset.id);
    }

    storage.setMeasurementUpdates(projectId, []);
    storage.setSiteUpdates(projectId, []);
    storage.setAttachmentUpdates(projectId, []);

    await clearAttachmentCache(projectId);

    storage.setAssets(projectId, []);
}

async function clearAllLocalProjectData() {
    const projects = await storage.getProjects();
    for (const project of projects) {
        await clearLocalProjectData(project.id);
    }
}

async function syncMeasurementUpdatesForProject(
    client: IApiClient,
    project: ProjectSummary,
    onProgress?: (status: string, percent: number) => void
) {
    const measurementUpdates = await storage.getMeasurementUpdates(project.id);
    if (measurementUpdates.length == 0) return;

    //we need to find our indicatorDataId (which may be new due to new site, so we grab the asset years here)
    const assetYears = await client.getAssetYears(project.id);

    let processed = 0;
    const total = measurementUpdates.length;
    for (const update of measurementUpdates.slice()) {
        onProgress?.('Uploading measurements', (processed / total) * 100);

        // find indicatorDataId for this change
        const assetYear = assetYears.find((ay) => ay.year == update.year && ay.projectAssetId == update.assetId);
        if (assetYear) {
            const measurementData = assetYear.measurementData.find(
                (md) => md.assessmentUnitId == update.assessmentUnitId
            );
            if (measurementData) {
                const indicatorData = measurementData.indicatorData.find(
                    (indd) => indd.indicatorId == update.indicatorId && indd.siteId == update.siteId
                );
                if (indicatorData) {
                    await client.updateIndicatorDataMeasurement(project.id, indicatorData.id, {
                        measurementDate: update.update.measurementDate,
                        measurementValue: update.update.measurementValue,
                    });
                }
            }
        }

        // TODO: handle the case where we couldn't find the indicatordata, some sort of error queue.
        // remove from pending updates
        const existingIdx = measurementUpdates.indexOf(update);
        if (existingIdx >= 0) {
            measurementUpdates.splice(existingIdx, 1);
        }
        await storage.setMeasurementUpdates(project.id, measurementUpdates);

        processed++;
    }
}

async function syncAttachmentUpdatesForProject(
    client: IApiClient,
    project: ProjectSummary,
    onProgress?: (status: string, percent: number) => void
) {
    const attachmentUpdates = await storage.getAttachmentUpdates(project.id);

    if (attachmentUpdates.length == 0) return;
    const attachmentCache = await storage.getAttachmentCache(project.id);

    //we may need to find our siteDataId or IndicatorDataId (which may be new due to new site, so we grab the asset years here)
    const assetYears = await client.getAssetYears(project.id);

    let processed = 0;
    const total = attachmentUpdates.length;
    for (const update of attachmentUpdates.slice()) {
        onProgress?.('Uploading attachments', (processed / total) * 100);

        const assetYear = assetYears.find((ay) => ay.year == update.year && ay.projectAssetId == update.assetId);
        if (assetYear) {
            const measurementData = assetYear.measurementData.find(
                (md) => md.assessmentUnitId == update.assessmentUnitId
            );
            if (measurementData) {
                if (update.updateKind == 'create') {
                    let newAttach: Attachment | undefined = undefined;
                    const blob = await readFileToBlob(update.localpath, update.mediaType);

                    // find indicatorDataId or siteId for this update and create an attachment
                    if (update.indicatorId) {
                        const indicatorData = measurementData.indicatorData.find(
                            (indd) => indd.indicatorId == update.indicatorId && indd.siteId == update.siteId
                        );

                        if (indicatorData) {
                            newAttach = await client.createProjectAttachment(
                                update.projectId,
                                'IndicatorData',
                                indicatorData.id,
                                update.attachmentType,
                                update.filename ?? '',
                                blob
                            );
                        }
                    } else {
                        const siteData = measurementData.siteData.find((sited) => sited.siteId == update.siteId);

                        if (siteData) {
                            newAttach = await client.createProjectAttachment(
                                update.projectId,
                                'SiteData',
                                siteData.id,
                                update.attachmentType,
                                update.filename ?? '',
                                blob
                            );
                        }
                    }

                    // Update the cache with the new Id
                    if (newAttach) {
                        const existingCache = attachmentCache.find((c) => c.attachmentId == update.attachmentId);
                        if (existingCache) {
                            existingCache.attachmentId = newAttach.id;
                        }
                        storage.setAttachmentCache(project.id, attachmentCache);
                    }
                }

                if (update.updateKind == 'replace') {
                    const blob = await readFileToBlob(update.localpath, update.mediaType);
                    await client.updateProjectAttachment(
                        update.projectId,
                        update.attachmentId,
                        update.filename ?? '',
                        blob
                    );
                }

                if (update.updateKind == 'delete') {
                    await client.deleteProjectAttachment(update.projectId, update.attachmentId);
                }
            }
        }
        // TODO: handle the case where we couldn't sync, some sort of error queue.
        // remove from pending updates
        const existingIdx = attachmentUpdates.indexOf(update);
        if (existingIdx >= 0) {
            attachmentUpdates.splice(existingIdx, 1);
        }
        await storage.setAttachmentUpdates(project.id, attachmentUpdates);

        processed++;
    }
}

async function syncSiteUpdatesForProject(
    client: IApiClient,
    project: ProjectSummary,
    onProgress?: (status: string, percent: number) => void
) {
    const siteUpdates = await storage.getSiteUpdates(project.id);
    if (siteUpdates.length == 0) return;

    let sitesProcessed = 0;
    const newSites = siteUpdates.filter((s) => s.updateKind == 'create');
    const totalNew = newSites.length;

    if (totalNew > 0) {
        const measurementUpdates = await storage.getMeasurementUpdates(project.id);
        const attachmentUpdates = await storage.getAttachmentUpdates(project.id);

        for (const newSite of newSites) {
            onProgress?.('Uploading New Sites', (sitesProcessed / totalNew) * 100);

            const site = await client.createSite(newSite.projectId, {
                assessmentUnitId: newSite.site.assessmentUnitId,
                name: newSite.site.name,
                location: newSite.site.location,
            });

            // remove from pending updates
            const existingIdx = siteUpdates.indexOf(newSite);
            if (existingIdx >= 0) {
                siteUpdates.splice(existingIdx, 1);
            }
            await storage.setSiteUpdates(project.id, siteUpdates);
            sitesProcessed++;

            //update the siteids for any pending measurement updates
            const newId = site.id;
            const oldId = newSite.site.id;

            const impactedMeasurements = measurementUpdates.filter((mu) => mu.siteId == oldId);
            for (const impactedMeasurement of impactedMeasurements) {
                impactedMeasurement.siteId = newId;
            }

            await storage.setMeasurementUpdates(project.id, measurementUpdates);

            //update the siteids for any pending attachment updates
            const impactedAttachments = attachmentUpdates.filter((mu) => mu.siteId == oldId);
            for (const impactedAttachment of impactedAttachments) {
                impactedAttachment.siteId = newId;
            }

            await storage.setAttachmentUpdates(project.id, attachmentUpdates);
        }
    }

    sitesProcessed = 0;
    const updatedSites = siteUpdates.filter((s) => s.updateKind != 'create');
    const totalUpdated = updatedSites.length;

    if (totalUpdated > 0) {
        for (const update of updatedSites) {
            onProgress?.('Uploading Site Updates', (sitesProcessed / totalUpdated) * 100);
            await client.updateSite(update.projectId, update.site.id, {
                name: update.site.name,
                location: update.site.location,
            });

            // remove from pending updates
            const existingIdx = siteUpdates.indexOf(update);
            if (existingIdx >= 0) {
                siteUpdates.splice(existingIdx, 1);
            }
            await storage.setSiteUpdates(project.id, siteUpdates);
            sitesProcessed++;
        }
    }
}

async function sendDataToAPI(client: IApiClient, onProgress?: (status: string, percent: number) => void) {
    // We can't blindly sync all measurements since this user might not have access to the project anymore etc.
    // TODO replace this "writable" test with an error queue, so that the updates are stashed if the project isn't writable and the user can "retry" all or abandon instead of having their updates ignored
    onProgress?.('Fetching writable projects', 0);
    const projects = await getWritableProjects(client);

    for (const project of projects) {
        await syncSiteUpdatesForProject(client, project, (status, percent) => onProgress?.(status, percent * 0.33));
        await syncMeasurementUpdatesForProject(client, project, (status, percent) =>
            onProgress?.(status, 33 + percent * 0.33)
        );
        await syncAttachmentUpdatesForProject(client, project, (status, percent) =>
            onProgress?.(status, 66 + percent * 0.34)
        );
    }
    onProgress?.('Upload Complete', 100);
}

async function populateAttachmentCacheForAssetYear(
    client: IApiClient,
    projectId: string,
    assetYear: AssetYear,
    onProgress?: (status: string, percent: number) => void
) {
    const siteDataAttachments = assetYear.measurementData
        .flatMap((md) => md.siteData)
        .flatMap((sd) => sd.attachments)
        .filter((a) => a.type == 'SiteDataPhoto');
    const indicatorDataAttachments = assetYear.measurementData
        .flatMap((md) => md.indicatorData)
        .flatMap((ind) => ind.attachments)
        .filter((a) => a.type == 'IndicatorDataPhoto');

    const attachmentCache = new AttachmentCacheStore();
    await attachmentCache.loadForProject(projectId);

    const attachments = [...siteDataAttachments, ...indicatorDataAttachments];
    for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];

        onProgress?.('Downloading Attachments', (i * 100) / attachments.length);

        if (attachmentCache.entries.find((e) => e.attachmentId == attachment.id)) continue;

        try {
            const url = await client.getProjectAttachmentUrl(projectId, attachment.id);
            const blob = await (await fetch(url)).blob();
            await attachmentCache.storeAttachment(attachment.id, blob);
        } catch (e) {
            console.error(`Error downloading attachment ${attachment.id}`, e);
            // failure to get an attachment shouldn't kill the sync
        }
    }
}

async function downloadDataFromAPI(client: IApiClient, onProgress?: (status: string, percent: number) => void) {
    let indicators: Indicator[] = [];
    await storage.setIndicators(indicators);

    onProgress?.('Fetching Projects', 0);
    const projects = await getWritableProjects(client);
    await storage.setProjects(projects);

    for (let i = 0; i < projects.length; i++) {
        onProgress?.('Fetching Indicators', 10 + (i / projects.length) * 20);
        const projIndicators = await getIndicators(client, projects[i].id);
        indicators = distinct(indicators.concat(projIndicators), (i) => i.id);
        await storage.setIndicators(indicators);
    }

    for (let i = 0; i < projects.length; i++) {
        onProgress?.(`Fetching Assets`, 30 + (i / projects.length) * 30);
        const assets = await getAssetsForProject(client, projects[i]);
        await storage.setAssets(projects[i].id, assets);
    }

    for (let i = 0; i < projects.length; i++) {
        onProgress?.(`Fetching Asset Data`, 60 + (i / projects.length) * 40);
        const assetYears = await getAssetYearsForProject(client, projects[i]);
        const assetIds = distinct(assetYears.map((ay) => ay.projectAssetId));
        for (const assetId of assetIds) {
            await storage.setAssetYears(
                projects[i].id,
                assetId,
                assetYears.filter((ay) => ay.projectAssetId == assetId)
            );
            if (assetYears.length > 0) {
                const latest = orderBy(assetYears, (ay) => ay.year, 'desc')[0];
                await populateAttachmentCacheForAssetYear(client, projects[i].id, latest); // TODO improve progress reporting
            }
        }
    }
}

export async function syncDataWithAPI(onProgress?: (status: string, percent: number) => void) {
    const client = getApiClient();

    await sendDataToAPI(client, (status, percent) => onProgress?.(status, 10 + percent * 0.9));
    await downloadDataFromAPI(client, (status, percent) => onProgress?.(status, 10 + percent * 0.9));

    onProgress?.('Sync Complete', 100);
}

export async function resetDataFromAPI(onProgress?: (status: string, percent: number) => void) {
    const client = getApiClient();

    onProgress?.('Deleting local data', 0);
    await clearAllLocalProjectData();
    await storage.setIndicators([]);

    await downloadDataFromAPI(client, (status, percent) => onProgress?.(status, 10 + percent * 0.9));

    onProgress?.('Reset Complete', 100);
}
