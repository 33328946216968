import {
    AccountingMethod,
    AssetClass,
    AssetType,
    Attachment,
    ContactDetails,
    CreateAccountingMethod,
    CreateAssetClass,
    CreateAssetType,
    CreateIndicator,
    CreateMeasurementStrategy,
    CreateProjectAsset,
    CreateProjectLocation,
    CreateReferenceBenchmarkStrategy,
    DeleteV1AdminAccountingMethodsIdResult,
    DeleteV1AdminIndicatorsIdResult,
    DeleteV1AdminMeasurementStrategiesIdResult,
    DeleteV1AdminReferenceBenchmarksIdResult,
    DeleteV1ProjectsProjectIdProjectAssetsProjectAssetIdResult,
    DeleteV1ProjectsProjectIdLocationsProjectLocationIdResult,
    Feature,
    GetV1ProjectsResult,
    GetV1ProjectsProjectIdResult,
    GetV1CadastreBoundsResult,
    GetV1LookupAccountingMethodsResult,
    GetV1LookupAssetClassesResult,
    GetV1LookupAssetTypesResult,
    GetV1LookupIndicatorsResult,
    GetV1LookupMeasurementStrategiesResult,
    GetV1LookupReferenceBenchmarkStrategiesResult,
    GetV1ProjectsProjectIdProjectAssetsProjectAssetIdResult,
    GetV1ProjectsProjectIdRegistrationResult,
    GetV1ProjectProjectIdAttachmentsAttachmentIdStorageUrlResult,
    GetV1ProjectProjectIdAttachmentsParentTypeParentEntityIdResult,
    GetV1ProjectsProjectIdLocationsResult,
    GetV1ProjectsProjectIdPreClearVegetationTypesResult,
    GetV1UserResult,
    Indicator,
    MeasurementStrategy,
    PostV1AdminAccountingMethodsResult,
    PostV1AdminAccountingMethodsTargetEntityIdResult,
    PostV1AdminAssetClassesResult,
    PostV1AdminAssetClassesTargetEntityIdResult,
    PostV1AdminAssetTypesResult,
    PostV1AdminAssetTypesTargetEntityIdResult,
    PostV1AdminIndicatorsResult,
    PostV1AdminIndicatorsTargetEntityIdResult,
    PostV1AdminMeasurementStrategiesResult,
    PostV1AdminMeasurementStrategiesTargetEntityIdResult,
    PostV1ProjectsResult,
    PostV1AdminReferenceBenchmarksResult,
    PostV1AdminReferenceBenchmarksTargetEntityIdResult,
    PostV1ProjectsProjectIdProjectAssetsResult,
    PostV1ProjectsProjectIdProponentContactDetailsResult,
    PostV1ProjectsProjectIdLocationsResult,
    ProjectAsset,
    ProjectLocation,
    ProjectRegistration,
    ProjectSummary,
    PutV1ProjectsProjectIdProjectAssetsTargetEntityIdResult,
    PutV1ProjectsProjectIdRegistrationResult,
    PutV1ProjectsProjectIdLocationsTargetEntityIdResult,
    ReferenceBenchmarkStrategy,
    UpdateAccountingMethod,
    UpdateAssetClass,
    UpdateAssetType,
    UpdateIndicator,
    UpdateProjectAsset,
    UpdateRegistration,
    User,
    VegetationTypeRegion,
    AttachmentParentType,
    AttachmentType,
    PostV1ProjectProjectIdAttachmentsAttachmentParentTypeParentIdResult,
    PostV1ProjectProjectIdAttachmentsAttachmentIdResult,
    UpdateProjectLocation,
    PostV1ProjectsProjectIdStratifyAssetByLandUseAndVegetationTypeTargetEntityIdResult,
    UpdateProjectFundamentals,
    GetV1ProjectsProjectIdValidationResult,
    GetV1ProjectsProjectIdValidationLocationsProjectLocationIdResult,
    GetV1ProjectsProjectIdValidationProjectAssetsProjectAssetIdResult,
    GetV1ProjectsProjectIdValidationRegistrationResult,
    UpdateRegistrationDeclaration,
    PutV1ProjectsProjectIdRegistrationDeclarationResult,
    PostV1AdminProjectApprovalProjectIdResult,
    ProjectInvite,
    CreateProjectInvite,
    UpdateProjectInvite,
    PostV1ProjectsProjectIdInvitesResult,
    PostV1ProjectsProjectIdInvitesTargetEntityIdResendResult,
    DeleteV1ProjectsProjectIdInvitesProjectInviteIdResult,
    PostV1ProjectsProjectIdInvitesTargetEntityIdResult,
    GetV1ProjectsProjectIdInvitesResult,
    ProjectAccess,
    AddOrUpdateProjectAccess,
    GetV1ProjectsProjectIdAccessResult,
    GetV1ProjectsAccessResult,
    PostV1ProjectsProjectIdAccessResult,
    DeleteV1ProjectsProjectIdAccessTargetUserIdResult,
    AcceptProjectInvite,
    PostV1ProjectsAcceptInviteResult,
    PostV1ProjectsProjectIdCondsProjectAssetsTargetEntityIdResult,
    CreateSite,
    Site,
    PostV1ProjectsProjectIdSitesResult,
    UpdateSite,
    PutV1ProjectsProjectIdSitesTargetEntityIdResult,
    UpdateIndicatorDataMeasurement,
    PostV1ProjectsProjectIdCondsIndicatorDataTargetEntityIdMeasurementResult,
    IndicatorData,
    UpdateIndicatorReferenceValue,
    DeleteV1ProjectsProjectIdSitesSiteIdResult,
    PostV1ProjectsProjectIdCondsIndicatorDataTargetEntityIdWithRemnantExtentResult,
    AssetCond,
    CondType,
    AssetYear,
    GetV1ProjectsProjectIdAssetYearsResult,
    InvoiceAddressDetails,
    PostV1ProjectsProjectIdInvoiceAddressDetailsResult,
    UpdateListingInformationForProject,
    PostV1ProjectsProjectIdListingInformationResult,
    PostV1ProjectsProjectIdFundamentalsResult,
    CreateEnvironmentalMarketLink,
    UpdateEnvironmentalMarketLink,
    EnvironmentalMarketLink,
    DeleteV1ProjectsProjectIdEnvironmentalMarketLinksTargetLinkIdResult,
    PostV1ProjectsProjectIdEnvironmentalMarketLinksTargetEntityIdResult,
    PostV1ProjectsProjectIdEnvironmentalMarketLinksResult,
    EnvironmentalMarket,
    GetV1LookupEnvironmentalMarketsResult,
    CreateEnvironmentalMarket,
    UpdateEnvironmentalMarket,
    PostV1AdminEnvironmentalMarketsResult,
    PostV1AdminEnvironmentalMarketsTargetEntityIdResult,
    DeleteV1AdminEnvironmentalMarketsIdResult,
    GetV1ProjectsProjectIdAssetYearsAssetYearIdResult,
    DeleteV1ProjectProjectIdAttachmentsAttachmentIdResult,
    GetV1AdminProjectApprovalResult,
    DeleteV1ProjectsProjectIdResult,
    CertificationRecord,
    PostV1ProjectsProjectIdCertificationResult,
    CertificationTier,
    UpdateProjectCertification,
    PostV1ProjectsProjectIdCertificationTargetEntityIdUpdateResult,
    PostV1ProjectsProjectIdCertificationTargetEntityIdRequestResult,
    PostV1ProjectsProjectIdCertificationTargetEntityIdWithdrawResult,
    GetV1ProjectsProjectIdIndicatorsResult,
    UpdateReferenceBenchmarkStrategy,
    UpdateMeasurementStrategy,
    GetV1AdminAccountingMethodsResult,
    Crs,
    FeatureCollection,
    PostV1CrsTransformTransformResult,
    PostV1ProjectsProjectIdCondsIndicatorReferenceValueTargetEntityIdResult,
    IndicatorReferenceValue,
    AssignIndicatorReferenceValueGroup,
    AssessmentUnitCond,
    PostV1ProjectsProjectIdAssessmentUnitCondTargetEntityIdAssignReferenceValuesByGroupResult,
    RenameIndicatorReferenceValues,
    CreateIndicatorReferenceValues,
    PostV1ProjectsProjectIdAssetCondsTargetEntityIdReferenceValueGroupResult,
    PostV1ProjectsProjectIdAssetCondsTargetEntityIdReferenceValueGroupGroupNameResult,
    AssetStratification,
    StratifyAssetByLandUseAndVegetationType,
    UpdateAssetStratification,
    PostV1ProjectsProjectIdProjectAssetsTargetEntityIdStratificationResult,
    UpdateAssessmentUnitCond,
    PostV1ProjectsProjectIdAssessmentUnitCondTargetEntityIdResult,
    ImportProjectInfo,
    PostV1AdminProjectImportProjectIdInfoResult,
    ImportProjectAsset,
    PostV1AdminProjectImportProjectIdAssetResult,
    ImportAssetYear,
    PostV1AdminProjectImportProjectIdAssetYearResult,
    BroadVegetationLevel,
    GetV1ProjectsProjectIdAvailableBroadVegetationLevelsResult,
    DeleteV1ProjectsProjectIdAssetYearAssetYearIdResult,
    UpdateProjectFutureAssets,
    PutV1ProjectsProjectIdFutureAssetsResult,
    ValidateIcsFormula,
    IcsFormulaErrors,
    PostV1AdminIndicatorsValidateIcsFormulaResult,
} from './ApiModel';

import { ApiFetch, JsonFetch } from './helper/RequestHelper';
import { isProblemDetails, ValidationErrors } from './helper/ErrorHelper';
import { tryGetToken } from './auth/auth';

// The type tree is too large and TS 4.3.3 chokes on it, removing these keys helps use get strong typing on validationErrors
export type ProjectAssetErrors = DeepOmit<ProjectAsset, 'attachments' | 'coordinates'>;

export interface IApiClient {
    importProjectAsset(projectId: string, asset: ImportProjectAsset): Promise<ProjectAsset>;

    importAssetYear(projectId: string, assetYear: ImportAssetYear): Promise<AssetYear>;
    validateICS(indicatorConditionInfo: ValidateIcsFormula): Promise<IcsFormulaErrors>;
    deleteAssetYear(projectId: string, assetYearId: string): Promise<void>;
    getBroadVegetationLevelsByProject(projectId: string): Promise<BroadVegetationLevel[]>;

    updateAssessmentUnitCond(
        projectId: string,
        assessmentUnitCondId: string,
        update: UpdateAssessmentUnitCond
    ): Promise<AssessmentUnitCond>;
    transformGeojson(crs: Crs, featureCollection: FeatureCollection): Promise<FeatureCollection>;

    renameGroupForAssetCond(
        projectId: string,
        assetCondId: string,
        oldname: string,
        update: RenameIndicatorReferenceValues
    ): Promise<IndicatorReferenceValue[]>;
    createGroupForAssetCond(
        projectId: string,
        assetCondId: string,
        update: CreateIndicatorReferenceValues
    ): Promise<IndicatorReferenceValue[]>;
    withdrawCertification(projectId: string, id: string): Promise<CertificationRecord>;
    requestCertification(projectId: string, id: string): Promise<CertificationRecord>;

    updateCertificationRecord(
        projectId: string,
        certificationRecordId: string,
        update: UpdateProjectCertification
    ): Promise<CertificationRecord>;
    createCertificationRecord(projectId: string, year: number, tier: CertificationTier): Promise<CertificationRecord>;
    disableProject(projectId: string): Promise<void>;
    getUnregisteredProjects(): Promise<ProjectSummary[]>;
    getProjectsPendingRegistration(): Promise<ProjectSummary[]>;
    updateIndicatorDataMeasurement(
        projectId: string,
        indicatorDataId: string,
        data: UpdateIndicatorDataMeasurement
    ): Promise<IndicatorData>;

    updateIndicatorReferenceValues(
        projectId: string,
        IndicatorReferenceValueId: string,
        data: UpdateIndicatorReferenceValue
    ): Promise<IndicatorReferenceValue>;

    updateIndicatorDataMeasurementWithRemnantExtent(projectId: string, indicatorDataId: string): Promise<IndicatorData>;
    createEnvironmentalMarket(item: CreateEnvironmentalMarket): Promise<EnvironmentalMarket>;
    updateEnvironmentalMarket(id: string, item: UpdateEnvironmentalMarket): Promise<EnvironmentalMarket>;
    deleteEnvironmentalMarket(id: string): Promise<void>;
    getEnvironmentalMarkets(): Promise<EnvironmentalMarket[]>;

    deleteProjectMarketLink(projectId: string, marketLinkId: string): Promise<void>;
    updateProjectMarketLink(
        projectId: string,
        marketLinkId: string,
        values: UpdateEnvironmentalMarketLink
    ): Promise<EnvironmentalMarketLink>;
    createProjectMarketLink(projectId: string, values: CreateEnvironmentalMarketLink): Promise<EnvironmentalMarketLink>;

    updateSite(projectId: string, siteId: string, update: UpdateSite): Promise<Site>;
    createSite(projectId: string, data: CreateSite): Promise<Site>;
    deleteSite(projectId: string, siteId: string): Promise<void>;

    updateProjectListingInformation(
        projectId: string,
        values: UpdateListingInformationForProject
    ): Promise<ProjectSummary>;
    approveProject(projectId: string, registeredProjectId: string): Promise<ProjectSummary>;
    declareCorrect(projectId: string, declaration: UpdateRegistrationDeclaration): Promise<ProjectRegistration>;
    getRegistrationValidationErrors(projectId: string): Promise<ValidationErrors<UpdateRegistration>>;
    getAssetValidationErrors(projectId: string, assetId: string): Promise<ValidationErrors<ProjectAssetErrors>>;
    getLocationValidation(projectId: string, id: string): Promise<ValidationErrors<ProjectLocation>>;
    getProjectValidationErrors(projectId: string): Promise<ValidationErrors<ProjectSummary>>;
    getIndicators(accountingMethodId: string): Promise<Indicator[]>;
    getIndicatorsByProject(projectId: string): Promise<Indicator[]>;
    createIndicator(create: CreateIndicator): Promise<Indicator>;
    updateIndicator(id: string, update: UpdateIndicator): Promise<Indicator>;
    deleteIndicator(id: string): Promise<void>;

    getMeasurementStrategies(accountingMethodId?: string): Promise<MeasurementStrategy[]>;
    createMeasurementStrategy(create: CreateMeasurementStrategy): Promise<MeasurementStrategy>;
    updateMeasurementStrategy(id: string, update: UpdateMeasurementStrategy): Promise<MeasurementStrategy>;
    deleteMeasurementStrategy(id: string): Promise<void>;

    getAssetClasses(): Promise<AssetClass[]>;
    updateAssetClass(id: string, update: UpdateAssetClass): Promise<AssetClass>;
    createAssetClass(create: CreateAssetClass): Promise<AssetClass>;
    deleteAssetClass(id: string): Promise<void>;

    getAssetTypes(): Promise<AssetType[]>;
    createAssetType(create: CreateAssetType): Promise<AssetType>;
    updateAssetType(id: string, update: UpdateAssetType): Promise<AssetType>;
    deleteAssetType(id: string): Promise<void>;

    getReferenceBenchmarkStrategies(accountingMethodId?: string): Promise<ReferenceBenchmarkStrategy[]>;
    createReferenceBenchmarkStrategy(create: CreateReferenceBenchmarkStrategy): Promise<ReferenceBenchmarkStrategy>;
    updateReferenceBenchmarkStrategy(
        id: string,
        update: UpdateReferenceBenchmarkStrategy
    ): Promise<ReferenceBenchmarkStrategy>;
    deleteReferenceBenchmarkStrategy(id: string): Promise<void>;

    getAccountingMethods(): Promise<AccountingMethod[]>;
    getAdminAccountingMethods(): Promise<AccountingMethod[]>;
    createAccountingMethod(create: CreateAccountingMethod): Promise<AccountingMethod>;
    updateAccountingMethod(id: string, update: UpdateAccountingMethod): Promise<AccountingMethod>;
    deleteAccountingMethod(id: string): Promise<void>;

    getProjectRegistration(projectId: string): Promise<ProjectRegistration>;
    getUser(): Promise<User>;
    //uploadAttachment(file: File, endpoint: string): Promise<AttachmentResult>;
    getProjectsForCurrentUser(): Promise<ProjectSummary[]>;
    createProject(userId: string, name: string): Promise<ProjectSummary>;
    getProject(id: string): Promise<ProjectSummary>;
    getProjectLocations(projectId: string): Promise<ProjectLocation[]>;
    addProjectLocation(projectId: string, location: CreateProjectLocation): Promise<ProjectLocation>;
    updateProjectLocation(
        projectId: string,
        locationId: string,
        location: UpdateProjectLocation
    ): Promise<ProjectLocation>;
    deleteProjectLocation(projectId: string, locationId: string): Promise<void>;

    getPropertyBounds(lat: number, lng: number, cancellationToken?: AbortSignal): Promise<Feature | null>;
    getPreclearVegetationTypeBoundsByProject(
        projectId: string,
        useHighLevelVegetationGroups?: boolean,
        level?: number
    ): Promise<VegetationTypeRegion[]>;

    updateProjectProponentContactDetails(projectId: string, details: ContactDetails): Promise<ContactDetails>;
    updateProjectInvoiceAddress(projectId: string, newDetails: InvoiceAddressDetails): Promise<InvoiceAddressDetails>;
    updateProjectFundamentals(projectId: string, data: UpdateProjectFundamentals): Promise<ProjectSummary>;

    updateProjectRegistration(projectId: string, info: UpdateRegistration): Promise<ProjectRegistration>;
    updateProjectFutureAssets(projectId: string, info: UpdateProjectFutureAssets): Promise<ProjectRegistration>;

    addProjectAsset(projectId: string, asset: CreateProjectAsset): Promise<ProjectAsset>;
    getProjectAsset(projectId: string, assetId: string): Promise<ProjectAsset>;
    updateProjectAsset(projectId: string, assetId: string, update: UpdateProjectAsset): Promise<ProjectAsset>;
    deleteProjectAsset(projectId: string, assetId: string): Promise<void>;
    updateAssetStratification(
        projectId: string,
        assetId: string,
        update: UpdateAssetStratification
    ): Promise<ProjectAsset>;

    getProjectInvites(projectId: string): Promise<ProjectInvite[]>;
    addProjectInvite(projectId: string, data: CreateProjectInvite): Promise<ProjectInvite>;
    updateProjectInvite(projectId: string, inviteId: string, data: UpdateProjectInvite): Promise<ProjectInvite>;
    deleteProjectInvite(projectId: string, inviteId: string): Promise<void>;
    resendProjectInvite(projectId: string, inviteId: string): Promise<ProjectInvite>;

    acceptProjectInvite(data: AcceptProjectInvite): Promise<ProjectSummary>;

    getProjectAccesses(projectId: string): Promise<ProjectAccess[]>;
    getProjectAccessesForCurrentUser(): Promise<ProjectAccess[]>;
    setProjectAccess(projectId: string, data: AddOrUpdateProjectAccess): Promise<ProjectAccess>;
    deleteProjectAccess(projectId: string, userId: string): Promise<void>;
    stratifyAssetByLandUseAndVegetationType(
        projectId: string,
        assetId: string,
        landuse: StratifyAssetByLandUseAndVegetationType
    ): Promise<AssetStratification>;

    getProjectAttachments(projectId: string): Promise<Attachment[]>;
    getProjectAttachmentUrl(projectId: string, id: string): Promise<string>;
    createProjectAttachment(
        projectId: string,
        parentType: AttachmentParentType,
        parentId: string,
        attachmentType: AttachmentType,
        attachmentName: string,
        file: Blob
    ): Promise<Attachment>;

    updateProjectAttachment(
        projectId: string,
        attachmentId: string,
        attachmentName: string,
        file: Blob
    ): Promise<Attachment>;

    deleteProjectAttachment(projectId: string, attachmentId: string): Promise<void>;

    /* Conds */

    getAssetYears(projectId: string, assetId?: string): Promise<AssetYear[]>;
    getAssetYear(projectId: string, AssetYearId: string): Promise<AssetYear>;
    createAssetCond(projectId: string, assetId: string, year: number, condType: CondType): Promise<AssetCond>;

    assignIndicatorReferenceGroups(
        projectId: string,
        assessmentUnitCondId: string,
        assign: AssignIndicatorReferenceValueGroup
    ): Promise<AssessmentUnitCond>;

    importProjectInfo(projectId: string, projectInfo: ImportProjectInfo): Promise<ProjectSummary>;
}

function ensureResult<T>(prom: Promise<T | undefined>): Promise<T> {
    return prom.then((res) => {
        if (typeof res === 'undefined') {
            throw {
                type: 'DataError',
                title: 'Response Data Error',
                detail: 'We received an unexpected empty response',
                instance: 'empty-response',
            };
        } else {
            return res;
        }
    });
}

export default class ApiClient implements IApiClient {
    constructor(public endpoint: string, private onAuthRequired?: () => void) {}

    importAssetYear(projectId: string, assetYear: ImportAssetYear): Promise<AssetYear> {
        return ensureResult(
            this.JsonPost<PostV1AdminProjectImportProjectIdAssetYearResult>(
                `/Admin/ProjectImport/${projectId}/AssetYear`,
                assetYear
            )
        );
    }

    importProjectAsset(projectId: string, asset: ImportProjectAsset): Promise<ProjectAsset> {
        return ensureResult(
            this.JsonPost<PostV1AdminProjectImportProjectIdAssetResult>(
                `/Admin/ProjectImport/${projectId}/Asset`,
                asset
            )
        );
    }

    importProjectInfo(projectId: string, projectInfo: ImportProjectInfo): Promise<ProjectSummary> {
        return ensureResult(
            this.JsonPost<PostV1AdminProjectImportProjectIdInfoResult>(
                `/Admin/ProjectImport/${projectId}/Info`,
                projectInfo
            )
        );
    }

    validateICS(indicatorConditionInfo: ValidateIcsFormula): Promise<IcsFormulaErrors> {
        return ensureResult(
            this.JsonPost<PostV1AdminIndicatorsValidateIcsFormulaResult>(
                `/Admin/Indicators/ValidateIcsFormula`,
                indicatorConditionInfo
            )
        );
    }

    deleteAssetYear(projectId: string, assetYearId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectsProjectIdAssetYearAssetYearIdResult>(
            `/Projects/${projectId}/AssetYear/${assetYearId}`
        );
    }

    async getBroadVegetationLevelsByProject(projectId: string): Promise<BroadVegetationLevel[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectsProjectIdAvailableBroadVegetationLevelsResult>(
                    `/Projects/${projectId}/AvailableBroadVegetationLevels`
                )
            )
        ).broadVegetationLevels;
    }

    updateAssessmentUnitCond(
        projectId: string,
        assessmentUnitCondId: string,
        update: UpdateAssessmentUnitCond
    ): Promise<AssessmentUnitCond> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdAssessmentUnitCondTargetEntityIdResult>(
                `/Projects/${projectId}/AssessmentUnitCond/${assessmentUnitCondId}`,
                update
            )
        );
    }

    updateAssetStratification(
        projectId: string,
        assetId: string,
        update: UpdateAssetStratification
    ): Promise<ProjectAsset> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdProjectAssetsTargetEntityIdStratificationResult>(
                `/Projects/${projectId}/ProjectAssets/${assetId}/Stratification`,
                update
            )
        );
    }

    transformGeojson(crs: Crs, featureCollection: FeatureCollection): Promise<FeatureCollection> {
        return ensureResult(
            this.JsonPost<PostV1CrsTransformTransformResult>(`/CrsTransform/Transform`, {
                crs: crs,
                featureCollection: featureCollection,
            })
        );
    }

    async renameGroupForAssetCond(
        projectId: string,
        assetCondId: string,
        oldname: string,
        update: RenameIndicatorReferenceValues
    ): Promise<IndicatorReferenceValue[]> {
        const res = await ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdAssetCondsTargetEntityIdReferenceValueGroupGroupNameResult>(
                `/Projects/${projectId}/AssetConds/${assetCondId}/ReferenceValueGroup/${oldname}`,
                update
            )
        );
        return res.indicatorReferenceValues;
    }

    async createGroupForAssetCond(
        projectId: string,
        assetCondId: string,
        update: CreateIndicatorReferenceValues
    ): Promise<IndicatorReferenceValue[]> {
        const res = await ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdAssetCondsTargetEntityIdReferenceValueGroupResult>(
                `/Projects/${projectId}/AssetConds/${assetCondId}/ReferenceValueGroup`,
                update
            )
        );
        return res.indicatorReferenceValues;
    }

    assignIndicatorReferenceGroups(
        projectId: string,
        assessmentUnitCondId: string,
        assign: AssignIndicatorReferenceValueGroup
    ): Promise<AssessmentUnitCond> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdAssessmentUnitCondTargetEntityIdAssignReferenceValuesByGroupResult>(
                `/Projects/${projectId}/AssessmentUnitCond/${assessmentUnitCondId}/AssignReferenceValuesByGroup`,
                assign
            )
        );
    }

    withdrawCertification(projectId: string, id: string): Promise<CertificationRecord> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCertificationTargetEntityIdWithdrawResult>(
                `/Projects/${projectId}/Certification/${id}/Withdraw`,
                {}
            )
        );
    }

    requestCertification(projectId: string, id: string): Promise<CertificationRecord> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCertificationTargetEntityIdRequestResult>(
                `/Projects/${projectId}/Certification/${id}/Request`,
                {}
            )
        );
    }

    updateCertificationRecord(projectId: string, certificationRecordId: string, update: UpdateProjectCertification) {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCertificationTargetEntityIdUpdateResult>(
                `/Projects/${projectId}/Certification/${certificationRecordId}/Update`,
                update
            )
        );
    }

    createCertificationRecord(projectId: string, year: number, tier: CertificationTier): Promise<CertificationRecord> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCertificationResult>(`/Projects/${projectId}/Certification`, {
                year: year,
                certificationTier: tier,
                allowDuplicate: false,
            })
        );
    }

    disableProject(projectId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectsProjectIdResult>(`/Projects/${projectId}`);
    }

    async getUnregisteredProjects(): Promise<ProjectSummary[]> {
        return (await ensureResult(this.JsonGet<GetV1AdminProjectApprovalResult>(`/Admin/ProjectApproval`)))
            .projectSummaries;
    }

    async getProjectsPendingRegistration(): Promise<ProjectSummary[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1AdminProjectApprovalResult>(`/Admin/ProjectApproval?registrationRequested=true`)
            )
        ).projectSummaries;
    }

    async getAssetYears(projectId: string, assetId?: string): Promise<AssetYear[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectsProjectIdAssetYearsResult>(
                    `/Projects/${projectId}/AssetYears${assetId ? `?projectAssetId=${assetId}` : ''}`
                )
            )
        ).assetYears;
    }

    getAssetYear(projectId: string, assetYearId: string): Promise<AssetYear> {
        return ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdAssetYearsAssetYearIdResult>(
                `/Projects/${projectId}/AssetYears/${assetYearId}`
            )
        );
    }

    createEnvironmentalMarket(item: CreateEnvironmentalMarket): Promise<EnvironmentalMarket> {
        return ensureResult(this.JsonPost<PostV1AdminEnvironmentalMarketsResult>(`/Admin/EnvironmentalMarkets`, item));
    }

    updateEnvironmentalMarket(id: string, item: UpdateEnvironmentalMarket): Promise<EnvironmentalMarket> {
        return ensureResult(
            this.JsonPost<PostV1AdminEnvironmentalMarketsTargetEntityIdResult>(
                `/Admin/EnvironmentalMarkets/${id}`,
                item
            )
        );
    }

    deleteEnvironmentalMarket(id: string): Promise<void> {
        return this.JsonDelete<DeleteV1AdminEnvironmentalMarketsIdResult>(`/Admin/EnvironmentalMarkets/${id}`);
    }

    async getEnvironmentalMarkets(cancellationToken?: AbortSignal): Promise<EnvironmentalMarket[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1LookupEnvironmentalMarketsResult>(`/Lookup/EnvironmentalMarkets`, cancellationToken)
            )
        ).environmentalMarkets;
    }

    async deleteProjectMarketLink(projectId: string, marketLinkId: string): Promise<void> {
        await this.JsonDelete<DeleteV1ProjectsProjectIdEnvironmentalMarketLinksTargetLinkIdResult>(
            `/Projects/${projectId}/EnvironmentalMarketLinks/${marketLinkId}`
        );
    }

    updateProjectMarketLink(
        projectId: string,
        marketLinkId: string,
        values: UpdateEnvironmentalMarketLink
    ): Promise<EnvironmentalMarketLink> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdEnvironmentalMarketLinksTargetEntityIdResult>(
                `/Projects/${projectId}/EnvironmentalMarketLinks/${marketLinkId}`,
                values
            )
        );
    }

    createProjectMarketLink(
        projectId: string,
        values: CreateEnvironmentalMarketLink
    ): Promise<EnvironmentalMarketLink> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdEnvironmentalMarketLinksResult>(
                `/Projects/${projectId}/EnvironmentalMarketLinks`,
                values
            )
        );
    }

    updateProjectListingInformation(
        projectId: string,
        values: UpdateListingInformationForProject
    ): Promise<ProjectSummary> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdListingInformationResult>(
                `/Projects/${projectId}/ListingInformation`,
                values
            )
        );
    }

    updateProjectInvoiceAddress(projectId: string, newDetails: InvoiceAddressDetails): Promise<InvoiceAddressDetails> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdInvoiceAddressDetailsResult>(
                `/Projects/${projectId}/InvoiceAddressDetails`,
                newDetails
            )
        );
    }

    acceptProjectInvite(data: AcceptProjectInvite): Promise<ProjectSummary> {
        return ensureResult(this.JsonPost<PostV1ProjectsAcceptInviteResult>(`/Projects/AcceptInvite`, data));
    }

    async getProjectAccesses(projectId: string): Promise<ProjectAccess[]> {
        return (await ensureResult(this.JsonGet<GetV1ProjectsProjectIdAccessResult>(`/Projects/${projectId}/Access`)))
            .projectAccesses;
    }

    async getProjectAccessesForCurrentUser(): Promise<ProjectAccess[]> {
        return (await ensureResult(this.JsonGet<GetV1ProjectsAccessResult>(`/Projects/Access`))).projectAccesses;
    }

    setProjectAccess(projectId: string, data: AddOrUpdateProjectAccess): Promise<ProjectAccess> {
        return ensureResult(this.JsonPost<PostV1ProjectsProjectIdAccessResult>(`/Projects/${projectId}/Access`, data));
    }

    async deleteProjectAccess(projectId: string, userId: string): Promise<void> {
        await this.JsonDelete<DeleteV1ProjectsProjectIdAccessTargetUserIdResult>(
            `/Projects/${projectId}/Access/${userId}`
        );
    }

    addProjectInvite(projectId: string, data: CreateProjectInvite): Promise<ProjectInvite> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdInvitesResult>(`/Projects/${projectId}/Invites`, data)
        );
    }

    updateProjectInvite(projectId: string, inviteId: string, data: UpdateProjectInvite): Promise<ProjectInvite> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdInvitesTargetEntityIdResult>(
                `/Projects/${projectId}/Invites/${inviteId}`,
                data
            )
        );
    }

    async deleteProjectInvite(projectId: string, inviteId: string): Promise<void> {
        await this.JsonDelete<DeleteV1ProjectsProjectIdInvitesProjectInviteIdResult>(
            `/Projects/${projectId}/Invites/${inviteId}`
        );
    }

    resendProjectInvite(projectId: string, inviteId: string): Promise<ProjectInvite> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdInvitesTargetEntityIdResendResult>(
                `/Projects/${projectId}/Invites/${inviteId}/Resend`,
                {}
            )
        );
    }

    async getProjectInvites(projectId: string): Promise<ProjectInvite[]> {
        return (await ensureResult(this.JsonGet<GetV1ProjectsProjectIdInvitesResult>(`/Projects/${projectId}/Invites`)))
            .projectInvites;
    }

    updateIndicatorDataMeasurement(
        projectId: string,
        indicatorDataId: string,
        data: UpdateIndicatorDataMeasurement
    ): Promise<IndicatorData> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCondsIndicatorDataTargetEntityIdMeasurementResult>(
                `/Projects/${projectId}/Conds/IndicatorData/${indicatorDataId}/Measurement`,
                data
            )
        );
    }

    updateIndicatorDataMeasurementWithRemnantExtent(
        projectId: string,
        indicatorDataId: string
    ): Promise<IndicatorData> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCondsIndicatorDataTargetEntityIdWithRemnantExtentResult>(
                `/Projects/${projectId}/Conds/IndicatorData/${indicatorDataId}/WithRemnantExtent`,
                {}
            )
        );
    }
    updateIndicatorReferenceValues(
        projectId: string,
        indicatorReferenceValueId: string,
        data: UpdateIndicatorReferenceValue
    ): Promise<IndicatorReferenceValue> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCondsIndicatorReferenceValueTargetEntityIdResult>(
                `/Projects/${projectId}/Conds/IndicatorReferenceValue/${indicatorReferenceValueId}`,
                data
            )
        );
    }

    updateSite(projectId: string, siteId: string, update: UpdateSite): Promise<Site> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdSitesTargetEntityIdResult>(
                `/Projects/${projectId}/Sites/${siteId}`,
                update
            )
        );
    }

    createSite(projectId: string, data: CreateSite): Promise<Site> {
        return ensureResult(this.JsonPost<PostV1ProjectsProjectIdSitesResult>(`/Projects/${projectId}/Sites`, data));
    }

    deleteSite(projectId: string, siteId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectsProjectIdSitesSiteIdResult>(`/Projects/${projectId}/Sites/${siteId}`);
    }

    createAssetCond(projectId: string, assetId: string, year: number, condType: CondType): Promise<AssetCond> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdCondsProjectAssetsTargetEntityIdResult>(
                `/Projects/${projectId}/Conds/ProjectAssets/${assetId}`,
                { year: year, condType: condType }
            )
        );
    }

    approveProject(projectId: string, registeredProjectId: string): Promise<ProjectSummary> {
        return ensureResult(
            this.JsonPost<PostV1AdminProjectApprovalProjectIdResult>(`/Admin/ProjectApproval/${projectId}`, {
                registeredProjectId: registeredProjectId,
            })
        );
    }

    declareCorrect(projectId: string, declaration: UpdateRegistrationDeclaration): Promise<ProjectRegistration> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdRegistrationDeclarationResult>(
                `/Projects/${projectId}/Registration/Declaration`,
                declaration
            )
        );
    }

    async getRegistrationValidationErrors(
        projectId: string,
        cancellationToken?: AbortSignal
    ): Promise<ValidationErrors<UpdateRegistration>> {
        const res = await ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdValidationRegistrationResult>(
                `/Projects/${projectId}/Validation/Registration`,
                cancellationToken
            )
        );
        return res.errors ?? {};
    }

    async getAssetValidationErrors(
        projectId: string,
        assetId: string,
        cancellationToken?: AbortSignal
    ): Promise<ValidationErrors<ProjectAssetErrors>> {
        const res = await ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdValidationProjectAssetsProjectAssetIdResult>(
                `/Projects/${projectId}/Validation/ProjectAssets/${assetId}`,
                cancellationToken
            )
        );
        return res.errors ?? {};
    }

    async getLocationValidation(
        projectId: string,
        id: string,
        cancellationToken?: AbortSignal
    ): Promise<ValidationErrors<ProjectLocation>> {
        const res = await ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdValidationLocationsProjectLocationIdResult>(
                `/Projects/${projectId}/Validation/Locations/${id}`,
                cancellationToken
            )
        );
        return res.errors ?? ({} as ValidationErrors<ProjectLocation>);
    }

    async getProjectValidationErrors(
        projectId: string,
        cancellationToken?: AbortSignal
    ): Promise<ValidationErrors<ProjectSummary>> {
        const res = await ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdValidationResult>(`/Projects/${projectId}/Validation`, cancellationToken)
        );
        return res.errors ?? ({} as ValidationErrors<ProjectSummary>);
    }

    deleteProjectAsset(projectId: string, assetId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectsProjectIdProjectAssetsProjectAssetIdResult>(
            `/Projects/${projectId}/ProjectAssets/${assetId}`
        );
    }

    updateProjectAsset(projectId: string, assetId: string, update: UpdateProjectAsset): Promise<ProjectAsset> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdProjectAssetsTargetEntityIdResult>(
                `/Projects/${projectId}/ProjectAssets/${assetId}`,
                update
            )
        );
    }

    getProjectAsset(projectId: string, assetId: string, cancellationToken?: AbortSignal): Promise<ProjectAsset> {
        return ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdProjectAssetsProjectAssetIdResult>(
                `/Projects/${projectId}/ProjectAssets/${assetId}`,
                cancellationToken
            )
        );
    }

    async getReferenceBenchmarkStrategies(
        accountingMethodId?: string,
        cancellationToken?: AbortSignal
    ): Promise<ReferenceBenchmarkStrategy[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1LookupReferenceBenchmarkStrategiesResult>(
                    `/Lookup/ReferenceBenchmarkStrategies${
                        accountingMethodId ? `?accountingMethodId=${accountingMethodId}` : ''
                    }`,
                    cancellationToken
                )
            )
        ).referenceBenchmarkStrategies;
    }

    createReferenceBenchmarkStrategy(create: CreateReferenceBenchmarkStrategy): Promise<ReferenceBenchmarkStrategy> {
        return ensureResult(this.JsonPost<PostV1AdminReferenceBenchmarksResult>(`/Admin/ReferenceBenchmarks/`, create));
    }

    updateReferenceBenchmarkStrategy(
        id: string,
        update: UpdateReferenceBenchmarkStrategy
    ): Promise<ReferenceBenchmarkStrategy> {
        return ensureResult(
            this.JsonPost<PostV1AdminReferenceBenchmarksTargetEntityIdResult>(
                `/Admin/ReferenceBenchmarks/${id}`,
                update
            )
        );
    }

    deleteReferenceBenchmarkStrategy(id: string): Promise<void> {
        return this.JsonDelete<DeleteV1AdminReferenceBenchmarksIdResult>(`/Admin/ReferenceBenchmarks/${id}`);
    }

    async getMeasurementStrategies(
        accountingMethodId?: string,
        cancellationToken?: AbortSignal
    ): Promise<MeasurementStrategy[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1LookupMeasurementStrategiesResult>(
                    `/Lookup/MeasurementStrategies${
                        accountingMethodId ? `?accountingMethodId=${accountingMethodId}` : ''
                    }`,
                    cancellationToken
                )
            )
        ).measurementStrategies;
    }

    createMeasurementStrategy(create: CreateMeasurementStrategy): Promise<MeasurementStrategy> {
        return ensureResult(
            this.JsonPost<PostV1AdminMeasurementStrategiesResult>(`/Admin/MeasurementStrategies`, create)
        );
    }

    updateMeasurementStrategy(id: string, update: UpdateMeasurementStrategy): Promise<MeasurementStrategy> {
        return ensureResult(
            this.JsonPost<PostV1AdminMeasurementStrategiesTargetEntityIdResult>(
                `/Admin/MeasurementStrategies/${id}`,
                update
            )
        );
    }

    deleteMeasurementStrategy(id: string): Promise<void> {
        return this.JsonDelete<DeleteV1AdminMeasurementStrategiesIdResult>(`/Admin/MeasurementStrategies/${id}`);
    }

    async getIndicatorsByProject(projectId: string, cancellationToken?: AbortSignal): Promise<Indicator[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectsProjectIdIndicatorsResult>(
                    `/Projects/${projectId}/Indicators`,
                    cancellationToken
                )
            )
        ).indicators;
    }

    async getIndicators(accountingMethodId: string, cancellationToken?: AbortSignal): Promise<Indicator[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1LookupIndicatorsResult>(
                    `/Lookup/Indicators?accountingMethodId=${accountingMethodId}`,
                    cancellationToken
                )
            )
        ).indicators;
    }

    createIndicator(create: CreateIndicator): Promise<Indicator> {
        return ensureResult(this.JsonPost<PostV1AdminIndicatorsResult>(`/Admin/Indicators`, create));
    }

    updateIndicator(id: string, update: UpdateIndicator): Promise<Indicator> {
        return ensureResult(
            this.JsonPost<PostV1AdminIndicatorsTargetEntityIdResult>(`/Admin/Indicators/${id}`, update)
        );
    }

    deleteIndicator(id: string): Promise<void> {
        return this.JsonDelete<DeleteV1AdminIndicatorsIdResult>(`/Admin/Indicators/${id}`);
    }

    createAccountingMethod(create: CreateAccountingMethod): Promise<AccountingMethod> {
        return ensureResult(this.JsonPost<PostV1AdminAccountingMethodsResult>(`/Admin/AccountingMethods`, create));
    }

    updateAccountingMethod(id: string, update: UpdateAccountingMethod): Promise<AccountingMethod> {
        return ensureResult(
            this.JsonPost<PostV1AdminAccountingMethodsTargetEntityIdResult>(`/Admin/AccountingMethods/${id}`, update)
        );
    }

    deleteAccountingMethod(id: string): Promise<void> {
        return this.JsonDelete<DeleteV1AdminAccountingMethodsIdResult>(`/Admin/AccountingMethods/${id}`);
    }

    getProjectRegistration(projectId: string, cancellationToken?: AbortSignal): Promise<ProjectRegistration> {
        return ensureResult(
            this.JsonGet<GetV1ProjectsProjectIdRegistrationResult>(
                `/Projects/${projectId}/Registration`,
                cancellationToken
            )
        );
    }

    updateProjectRegistration(projectId: string, info: UpdateRegistration): Promise<ProjectRegistration> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdRegistrationResult>(`/Projects/${projectId}/Registration`, info)
        );
    }

    updateProjectFutureAssets(projectId: string, info: UpdateProjectFutureAssets): Promise<ProjectRegistration> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdFutureAssetsResult>(`/Projects/${projectId}/FutureAssets`, info)
        );
    }

    async updateProjectProponentContactDetails(projectId: string, details: ContactDetails): Promise<ContactDetails> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdProponentContactDetailsResult>(
                `/Projects/${projectId}/ProponentContactDetails`,
                details
            )
        );
    }

    updateProjectFundamentals(projectId: string, data: UpdateProjectFundamentals): Promise<ProjectSummary> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdFundamentalsResult>(`/Projects/${projectId}/Fundamentals`, data)
        );
    }

    deleteProjectLocation(projectId: string, locationId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectsProjectIdLocationsProjectLocationIdResult>(
            `/Projects/${projectId}/Locations/${locationId}`
        );
    }

    async getPreclearVegetationTypeBoundsByProject(
        projectId: string,
        useHighLevelVegetationGroups?: boolean,
        level?: number,
        cancellationToken?: AbortSignal
    ): Promise<VegetationTypeRegion[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectsProjectIdPreClearVegetationTypesResult>(
                    `/Projects/${projectId}/PreClearVegetationTypes${
                        useHighLevelVegetationGroups
                            ? `?useHighLevelVegetationGroups=true${level != undefined ? `&level=${level}` : ''}`
                            : ''
                    }`,
                    cancellationToken
                )
            )
        ).vegetationTypes;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getProjectLocations(projectId: string, cancellationToken?: AbortSignal): Promise<ProjectLocation[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectsProjectIdLocationsResult>(
                    `/Projects/${projectId}/Locations`,
                    cancellationToken
                )
            )
        ).projectLocations;
    }

    async addProjectLocation(projectId: string, location: CreateProjectLocation): Promise<ProjectLocation> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdLocationsResult>(`/Projects/${projectId}/Locations`, location)
        );
    }

    async updateProjectLocation(
        projectId: string,
        locationId: string,
        location: UpdateProjectLocation
    ): Promise<ProjectLocation> {
        return ensureResult(
            this.JsonPut<PutV1ProjectsProjectIdLocationsTargetEntityIdResult>(
                `/Projects/${projectId}/Locations/${locationId}`,
                location
            )
        );
    }

    getPropertyBounds(lat: number, lng: number, cancellationToken?: AbortSignal): Promise<Feature | null> {
        return this.JsonGet<GetV1CadastreBoundsResult>(`/Cadastre/Bounds?lat=${lat}&lng=${lng}`, cancellationToken);
    }

    async getAssetTypes(cancellationToken?: AbortSignal): Promise<AssetType[]> {
        return (
            (await ensureResult(this.JsonGet<GetV1LookupAssetTypesResult>('/Lookup/AssetTypes', cancellationToken)))
                .assetTypes ?? []
        );
    }

    createAssetType(create: CreateAssetType): Promise<AssetType> {
        return ensureResult(this.JsonPost<PostV1AdminAssetTypesResult>(`/Admin/AssetTypes`, create));
    }

    updateAssetType(id: string, update: UpdateAssetType): Promise<AssetType> {
        return ensureResult(
            this.JsonPost<PostV1AdminAssetTypesTargetEntityIdResult>(`/Admin/AssetTypes/${id}`, update)
        );
    }

    deleteAssetType(id: string): Promise<void> {
        return this.JsonDelete(`/Admin/AssetTypes/${id}`);
    }

    async getAssetClasses(cancellationToken?: AbortSignal): Promise<AssetClass[]> {
        return (
            (await ensureResult(this.JsonGet<GetV1LookupAssetClassesResult>('/Lookup/AssetClasses', cancellationToken)))
                .assetClasses ?? []
        );
    }

    updateAssetClass(id: string, update: UpdateAssetClass): Promise<AssetClass> {
        return ensureResult(
            this.JsonPost<PostV1AdminAssetClassesTargetEntityIdResult>(`/Admin/AssetClasses/${id}`, update)
        );
    }

    createAssetClass(create: CreateAssetClass): Promise<AssetClass> {
        return ensureResult(this.JsonPost<PostV1AdminAssetClassesResult>(`/Admin/AssetClasses`, create));
    }

    deleteAssetClass(id: string): Promise<void> {
        return this.JsonDelete(`/Admin/AssetClasses/${id}`);
    }

    async getAdminAccountingMethods(cancellationToken?: AbortSignal): Promise<AccountingMethod[]> {
        return (
            (
                await ensureResult(
                    this.JsonGet<GetV1AdminAccountingMethodsResult>('/Admin/AccountingMethods', cancellationToken)
                )
            ).accountingMethods ?? []
        );
    }

    async getAccountingMethods(cancellationToken?: AbortSignal): Promise<AccountingMethod[]> {
        return (
            (
                await ensureResult(
                    this.JsonGet<GetV1LookupAccountingMethodsResult>('/Lookup/AccountingMethods', cancellationToken)
                )
            ).accountingMethods ?? []
        );
    }

    getProject(id: string, cancellationToken?: AbortSignal): Promise<ProjectSummary> {
        return this.JsonGet<GetV1ProjectsProjectIdResult>(`/Projects/${id}`, cancellationToken);
    }

    createProject(userId: string, name: string): Promise<ProjectSummary> {
        return ensureResult(this.JsonPost<PostV1ProjectsResult>(`/Projects`, { name: name }));
    }

    async getProjectsForCurrentUser(cancellationToken?: AbortSignal): Promise<ProjectSummary[]> {
        return (await this.JsonGet<GetV1ProjectsResult>(`/Projects`, cancellationToken)).projectSummaries;
    }

    addProjectAsset(projectId: string, asset: CreateProjectAsset): Promise<ProjectAsset> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdProjectAssetsResult>(`/Projects/${projectId}/ProjectAssets`, asset)
        );
    }

    async getProjectAttachments(projectId: string, cancellationToken?: AbortSignal): Promise<Attachment[]> {
        return (
            await ensureResult(
                this.JsonGet<GetV1ProjectProjectIdAttachmentsParentTypeParentEntityIdResult>(
                    `/Project/${projectId}/Attachments/Project/${projectId}`,
                    cancellationToken
                )
            )
        ).attachments;
    }

    async getProjectAttachmentUrl(projectId: string, id: string): Promise<string> {
        const url = (
            await this.JsonGet<GetV1ProjectProjectIdAttachmentsAttachmentIdStorageUrlResult>(
                `/Project/${projectId}/Attachments/${id}/StorageUrl`
            )
        ).url;
        if (!url) throw new Error("Couldn't get url for attachment");
        return url;
    }

    getUser(cancellationToken?: AbortSignal): Promise<User> {
        return this.JsonGet<GetV1UserResult>('/user', cancellationToken);
    }

    createProjectAttachment(
        projectId: string,
        parentType: AttachmentParentType,
        parentId: string,
        attachmentType: AttachmentType,
        attachmentName: string,
        file: Blob
    ): Promise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        return ensureResult(
            this.ApiRequest<PostV1ProjectProjectIdAttachmentsAttachmentParentTypeParentIdResult>(
                `/Project/${projectId}/Attachments/${parentType}/${parentId}?filename=${encodeURIComponent(
                    attachmentName
                )}&attachmentType=${attachmentType}`,
                'POST',
                undefined,
                formData
            )
        );
    }

    updateProjectAttachment(
        projectId: string,
        attachmentId: string,
        attachmentName: string,
        file: Blob
    ): Promise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        return ensureResult(
            this.ApiRequest<PostV1ProjectProjectIdAttachmentsAttachmentIdResult>(
                `/Project/${projectId}/Attachments/${attachmentId}?filename=${encodeURIComponent(attachmentName)}`,
                'POST',
                undefined,
                formData
            )
        );
    }

    deleteProjectAttachment(projectId: string, attachmentId: string): Promise<void> {
        return this.JsonDelete<DeleteV1ProjectProjectIdAttachmentsAttachmentIdResult>(
            `/Project/${projectId}/Attachments/${attachmentId}`
        );
    }

    stratifyAssetByLandUseAndVegetationType(
        projectId: string,
        assetId: string,
        landuses: StratifyAssetByLandUseAndVegetationType
    ): Promise<AssetStratification> {
        return ensureResult(
            this.JsonPost<PostV1ProjectsProjectIdStratifyAssetByLandUseAndVegetationTypeTargetEntityIdResult>(
                `/Projects/${projectId}/StratifyAssetByLandUseAndVegetationType/${assetId}`,
                landuses
            )
        );
    }

    /*
    async uploadAttachment(file: File, endpoint: string): Promise<AttachmentResult> {
        const formData = new FormData();
        formData.append('file', file);
        const token = (await tryGetToken())?.accessToken;
        return ensureResult(
            ApiFetch<AttachmentResult>(this.endpoint + endpoint, 'POST', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                body: formData,
            }).catch((e) => this.handleFetchError(e))
        );
    }*/

    handleFetchError(e: unknown): never {
        // Single place to handle auth errors
        if (!isProblemDetails(e)) throw e;
        if (e.status == 401 && this.onAuthRequired) {
            this.onAuthRequired();
            throw e;
        } else {
            throw e;
        }
    }

    async JsonRequest<T>(
        path: string,
        method: string,
        body?: unknown,
        cancellationToken?: AbortSignal
    ): Promise<T | undefined> {
        try {
            const headers: Record<string, string> = {};
            const token = (await tryGetToken())?.accessToken;
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            return await JsonFetch(path.startsWith('http') ? path : this.endpoint + path, method, {
                body: body,
                cancellationToken: cancellationToken,
                headers: headers,
            });
        } catch (e) {
            this.handleFetchError(e);
        }
    }

    async ApiRequest<T>(
        path: string,
        method: string,
        headers?: Record<string, string>,
        body?: BodyInit,
        cancellationToken?: AbortSignal
    ): Promise<T | undefined> {
        try {
            headers = headers ?? {};

            const token = (await tryGetToken())?.accessToken;
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            return await ApiFetch(path.startsWith('http') ? path : this.endpoint + path, method, {
                body: body,
                cancellationToken: cancellationToken,
                headers: headers,
            });
        } catch (e) {
            this.handleFetchError(e);
        }
    }

    async JsonGet<T>(path: string, cancellationToken?: AbortSignal): Promise<T> {
        return ensureResult(this.JsonRequest<T>(path, 'GET', undefined, cancellationToken));
    }

    async JsonDelete<T>(path: string): Promise<T | undefined> {
        return this.JsonRequest<T>(path, 'DELETE');
    }

    async JsonPost<T>(path: string, body: unknown): Promise<T | undefined> {
        return this.JsonRequest<T>(path, 'POST', body);
    }

    async JsonPut<T>(path: string, body: unknown): Promise<T | undefined> {
        return this.JsonRequest<T>(path, 'PUT', body);
    }

    async JsonPatch<T>(path: string, body: unknown): Promise<T | undefined> {
        return this.JsonRequest<T>(path, 'PATCH', body);
    }
}
