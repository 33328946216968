import { IonButton, IonIcon, IonModal } from '@ionic/react';
import React, { useState } from 'react';

type ModalToggleButtonProps = {
    icon?: string;
    label: string;
    children?: (onClose: () => void) => React.ReactNode;
};

export default function ModalToggleButton({ children, icon, label }: ModalToggleButtonProps) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <IonButton expand="block" color="tertiary" onClick={() => setShowModal(true)}>
                {icon && <IonIcon icon={icon}></IonIcon>}
                {icon && label && <>&nbsp;</>}
                {label}
            </IonButton>
            <IonModal isOpen={showModal} showBackdrop onDidDismiss={() => setShowModal(false)}>
                {children?.(() => setShowModal(false))}
                <IonButton onClick={() => setShowModal(false)}>Cancel &amp; Close</IonButton>
            </IonModal>
        </>
    );
}
