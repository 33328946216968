import {
    IonContent,
    IonList,
    IonButton,
    IonLabel,
    IonItem,
    IonProgressBar,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
} from '@ionic/react';
import produce from 'immer';
import { useState } from 'react';
import { Indicator } from '../../../lib/ApiModel';
import { range } from '../../../lib/helper/ArrayHelper';
import { CalculatorProps } from './CalculatorButton';
import { chevronBackSharp, chevronForwardSharp } from 'ionicons/icons';
import { formatNumber } from '../../../lib/helper/StringHelper';
type StepCountCalculatorProps = CalculatorProps;

type IndicatorValue = {
    indicator: Indicator;
    present: boolean;
};

type StepSample = {
    step: number;
    values: IndicatorValue[];
};

export default function StepCountCalculator({ indicators, onChange }: StepCountCalculatorProps) {
    const maxSteps = 30;
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [stepData, setStepData] = useState<StepSample[]>(
        range(1, maxSteps).map((i) => ({ step: i, values: indicators.map((i) => ({ indicator: i, present: false })) }))
    );
    const completed = currentStep > maxSteps;

    const setIndicatorValue = (step: number, indicatorId: string, value: boolean) => {
        console.log(`Setting indicator value ${value ? 'true' : 'false'}`);
        const updated = produce(stepData, (sd) => {
            const thisStep = sd.find((s) => s.step == step);
            if (!thisStep) return;
            const thisVal = thisStep.values.find((v) => v.indicator.id == indicatorId);
            if (!thisVal) return;
            thisVal.present = value;
        });

        setStepData(updated);
    };

    const getIndicatorValue = (step: number, indicatorId: string) => {
        const thisStep = stepData.find((s) => s.step == step);
        return thisStep?.values.find((v) => v.indicator.id == indicatorId)?.present ?? false;
    };

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(Math.max(0, currentStep - 1));

    const getPercent = (indicatorId: string) => {
        const all = stepData.length;
        const present = stepData
            .map((sd) => sd.values.find((v) => v.indicator.id == indicatorId)?.present ?? false)
            .filter(Boolean).length;
        return Math.round((present / all) * 100 * 10) / 10;
    };

    const saveData = () => {
        const newValuesArray = indicators.map((i) => getPercent(i.id));
        onChange(newValuesArray);
    };

    const sanitiseIndicatorName = (name: string) => {
        return name.replace('(%)', '');
    };

    return (
        <IonContent>
            <div className="ion-padding">
                <IonProgressBar value={(currentStep - 1) / maxSteps}></IonProgressBar>
                {!completed && (
                    <>
                        <h4>
                            Step {currentStep} of {maxSteps}
                        </h4>
                        <IonList>
                            {indicators.map((i) => (
                                <IonItem key={`${currentStep}_${i.id}`}>
                                    <IonCheckbox
                                        checked={getIndicatorValue(currentStep, i.id)}
                                        onIonChange={(e) => setIndicatorValue(currentStep, i.id, e.detail.checked)}
                                        slot="start"
                                    />
                                    <IonLabel>{sanitiseIndicatorName(i.name)}</IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" onClick={prevStep}>
                                        <IonIcon slot="start" icon={chevronBackSharp} /> Prev
                                    </IonButton>
                                </IonCol>
                                <IonCol style={{ textAlign: 'right' }}>
                                    <IonButton expand="block" onClick={nextStep}>
                                        Next <IonIcon slot="end" icon={chevronForwardSharp} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                )}
                {completed && (
                    <div>
                        <h2>Step Count Completed!</h2>
                        <h3>Step count results:</h3>
                        <IonList>
                            {indicators.map((i) => (
                                <IonItem key={i.id}>
                                    <IonLabel>{sanitiseIndicatorName(i.name)}</IonLabel>
                                    <IonLabel slot="end" color="primary" style={{ textAlign: 'right' }}>
                                        {formatNumber(getPercent(i.id), 1, '%')}
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>

                        <div className="ion-padding">
                            <IonButton
                                onClick={() => {
                                    saveData();
                                }}
                                expand="block"
                                color="secondary"
                            >
                                Save Results
                            </IonButton>
                        </div>
                    </div>
                )}
            </div>
        </IonContent>
    );
}
