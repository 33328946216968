import { EventEmitter } from '../helper/EventHelper';
import storage from '../Storage';
import { AttachmentCacheEntry, blobToBase64 } from '../helper/AttachmentHelper';
import produce from 'immer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { generateId } from '../helper/IdHelper';

export default class AttachmentCacheStore {
    onChange = new EventEmitter<AttachmentCacheStore>();
    entries: AttachmentCacheEntry[] = [];
    projectId = '';

    async loadForProject(projectId: string): Promise<AttachmentCacheEntry[]> {
        this.entries = await storage.getAttachmentCache(projectId);
        this.projectId = projectId;
        this.onChange.fire(this);
        return this.entries;
    }

    async addOrUpdateEntry(entry: AttachmentCacheEntry) {
        const newEntries = produce(this.entries, (entries) => {
            const existingIndex = entries.findIndex((e) => e.attachmentId == entry.attachmentId);
            if (existingIndex >= 0) {
                const existingEntry = entries[existingIndex];
                Filesystem.deleteFile({
                    path: existingEntry.localfilepath,
                });
                entries.splice(existingIndex, 1);
            }
            entries.push(entry);
        });
        this.entries = newEntries;
        await this.save();
        this.onChange.fire(this);
    }

    async storeAttachment(attachmentId: string, blob: Blob): Promise<AttachmentCacheEntry> {
        const database64 = await blobToBase64(blob);
        const res = await Filesystem.writeFile({
            data: database64,
            path: 'attachment-' + generateId(),
            directory: Directory.Data,
        });

        const entry: AttachmentCacheEntry = {
            attachmentId,
            localfilepath: res.uri,
        };
        await this.addOrUpdateEntry(entry);
        return entry;
    }

    async removeAllEntries() {
        for (const entry of this.entries) {
            await Filesystem.deleteFile({
                path: entry.localfilepath,
            });
        }
        this.entries = [];
        this.save();
        this.onChange.fire(this);
    }

    removeEntry(attachmentId: string) {
        const newEntries = produce(this.entries, (entries) => {
            const existingIndex = entries.findIndex((e) => e.attachmentId == attachmentId);
            if (existingIndex >= 0) {
                const existingEntry = entries[existingIndex];
                Filesystem.deleteFile({
                    path: existingEntry.localfilepath,
                });
                entries.splice(existingIndex, 1);
            }
        });
        this.entries = newEntries;
        this.save();
        this.onChange.fire(this);
    }

    async save() {
        if (!this.entries) return;
        await storage.setAttachmentCache(this.projectId, this.entries);
    }
}
