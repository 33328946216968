import { EventEmitter } from '../helper/EventHelper';
import { Indicator } from '../ApiModel';
import storage from '../Storage';

export default class IndicatorsStore {
    onChange = new EventEmitter<IndicatorsStore>();
    indicators: Indicator[] = [];

    async load() {
        this.indicators = await storage.getIndicators();
        this.onChange.fire(this);
    }
}
