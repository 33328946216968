export function orderBy<T, U>(items: T[], selector: (item: T) => U, direction: 'desc' | 'asc' = 'asc'): T[] {
    const sorted = items.map((el, index) => [selector(el), index, el] as [U, number, T]);
    sorted.sort((a, b) => {
        const sela = a[0];
        const selb = b[0];
        if (sela == selb) return a[1] - b[1]; //fall back to index to provide "stable" sort order
        return (sela > selb ? 1 : -1) * (direction == 'desc' ? -1 : 1);
    });
    return sorted.map((i) => i[2]);
}

export function reverse<T>(items: T[]): T[] {
    const res = [];
    for (let i = items.length - 1; i >= 0; i--) {
        res.push(items[i]);
    }
    return res;
}

export function range(start: number, finish: number, step = 1): number[] {
    const res = [];
    for (let i = start; i <= finish; i = i + step) {
        res.push(i);
    }
    return res;
}

export function orderByLookup<T, U>(
    items: T[],
    lookup: U[],
    selector: (item: T) => U,
    direction: 'desc' | 'asc' = 'asc'
) {
    return orderBy(
        items,
        (d) => {
            const ord = lookup.indexOf(selector(d));
            return ord == -1 ? lookup.length + items.indexOf(d) : ord;
        },
        direction
    );
}

export function hasSameValuesIgnoringOrderAndDuplicates<T>(a: T[], b: T[]): boolean {
    return (
        a.findIndex((x) => b.findIndex((y) => y == x) == -1) == -1 &&
        b.findIndex((x) => a.findIndex((y) => y == x) == -1) == -1
    );
}

export function distinct<T>(items: T[], selector: (item: T) => unknown = (x) => x) {
    const res: T[] = [];
    const seen: unknown[] = [];
    for (const item of items) {
        const key = selector(item);
        if (seen.indexOf(key) >= 0) continue;
        seen.push(key);
        res.push(item);
    }
    return res;
}

export function sum<T>(items: T[], selector: (item: T) => number = (x) => Number(x)) {
    let sum = 0;
    for (const item of items) {
        sum = sum + selector(item);
    }
    return sum;
}

export function hasSameItems<T>(a: T[], b: T[], idFunc: (item: T) => unknown = (x) => x): boolean {
    if (a.length != b.length) return false;
    const bUsedIdx = new Set<number>();
    const bVals = b.map((i) => idFunc(i));
    for (let idx = 0; idx < a.length; idx++) {
        const aVal = idFunc(a[idx]);
        const bIdx = bVals.findIndex((bVal, bIdx) => bVal == aVal && !bUsedIdx.has(bIdx));
        if (bIdx == -1) return false;
        bUsedIdx.add(bIdx);
    }
    return true;
}

export function hasSameSequence<T>(a: T[], b: T[], idFunc: (item: T) => unknown = (x) => x) {
    if (!a || !b) return false;
    if (a.length != b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (idFunc(a[i]) !== idFunc(b[i])) return false;
    }
    return true;
}

export function toggleItem<T>(collection: T[], item: T, idFunc: (item: T) => unknown = (x) => x): T[] {
    if (!collection) return [item];
    const itemId = idFunc(item);
    if (collection.find((i) => idFunc(i) == itemId)) {
        return collection.filter((i) => idFunc(i) != itemId);
    } else {
        return [...collection, item];
    }
}

export function isArray(val: unknown): val is unknown[] {
    return val !== null && Array.isArray(val);
}

export function selectMany<T>(coll: T[][]): T[] {
    const out = [];
    for (const arr of coll) {
        out.push(...arr);
    }
    return out;
}

export function all<T>(a: T[], predicate: (item: T) => boolean): boolean {
    return sum(a, (i) => (predicate(i) ? 0 : 1)) == 0;
}

export function exclude<T>(target: T[], toexclude: T[], idFunc: (item: T) => unknown = (x) => x): T[] {
    const exclMap = new Set(toexclude.map((x) => idFunc(x)));
    return target.filter((x) => !exclMap.has(idFunc(x)));
}

/**
 * Returns the array without "undefined" or "null" values
 * @param target array to remove undefined or "null" values
 * @returns
 */
export function compact<T>(target: (T | undefined | null)[]): T[] {
    return target.filter((t) => t !== undefined && t !== null) as T[];
}
