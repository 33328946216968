import * as msal from '@azure/msal-browser';
import { EventEmitter } from '../helper/EventHelper';

let auth_client: msal.PublicClientApplication | undefined = undefined;

export interface AuthConfig {
    clientId: string;
    redirectUrl?: string;
    tenantName: string;
    apiScope: string;
    postLogoutRedirectUrl?: string;
    onRedirectNavigate?: (url: string) => boolean | void;
}

let onRedirectNavigate: ((url: string) => boolean | void) | undefined;

export const onLoginStateChanged: EventEmitter = new EventEmitter();

let apiScope: string;
let passwordResetAuthority: string;

export function initializeAuth(authConfig: AuthConfig) {
    onRedirectNavigate = authConfig.onRedirectNavigate;
    const redirector = (url: string, opts: msal.NavigationOptions) => {
        console.log('redirecting from auth...', url, opts);
        onRedirectNavigate?.(url);
        // we should never return, we have been redirected.
        return new Promise<boolean>(() => {});
    };
    passwordResetAuthority = `https://${authConfig.tenantName}.b2clogin.com/${authConfig.tenantName}.onmicrosoft.com/B2C_1_ResetPassword`;
    apiScope = authConfig.apiScope;
    auth_client = new msal.PublicClientApplication({
        auth: {
            authority: `https://${authConfig.tenantName}.b2clogin.com/${authConfig.tenantName}.onmicrosoft.com/B2C_1_signupsignin`,
            knownAuthorities: [`${authConfig.tenantName}.b2clogin.com`],
            clientId: authConfig.clientId,
            redirectUri: authConfig.redirectUrl,
            postLogoutRedirectUri: authConfig.postLogoutRedirectUrl,
        },
        cache: {
            cacheLocation: msal.BrowserCacheLocation.LocalStorage,
        },
        system: {
            navigationClient: {
                navigateExternal: redirector,
                navigateInternal: redirector,
            },
        },
    });
}

function getAccount() {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');
    const account = auth_client.getActiveAccount();
    if (account) return account;
    const accounts = auth_client.getAllAccounts();
    if (!accounts?.length) return null;
    return accounts[0];
}

export async function tryGetToken() {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');
    try {
        const account = getAccount();
        if (!account) return null;
        return await auth_client.acquireTokenSilent({ scopes: [apiScope], account: account });
    } catch (error) {
        if (!(error instanceof msal.InteractionRequiredAuthError)) {
            console.error("Couldn't aquire token", error);
        }
        return null;
    }
}

export async function isLoggedIn() {
    const token = await tryGetToken();
    return !!token;
}

export interface User {
    username: string;
    oid: string;
    name?: string;
}

export function currentUser(): User | null {
    const account = getAccount();
    if (!account) return null;

    return {
        username: account.username,
        oid: account.localAccountId,
        name: account.name,
    };
}

export async function loginWithRedirect() {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');

    await auth_client.loginRedirect({ scopes: [apiScope] });
    return null;
}

export async function logoutWithRedirect() {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');

    const account = getAccount();
    if (!account) return;

    await auth_client.logoutRedirect({
        account: account,
    });

    return null;
}

export async function getTokenWithRedirect() {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');

    const account = getAccount();
    if (!account) {
        await loginWithRedirect();
        return null;
    }

    const token = await tryGetToken();
    if (token) {
        console.log('Got token');
        return token;
    }

    console.log('failed to get token, redirecting');
    await auth_client.acquireTokenRedirect({
        scopes: [apiScope],
        account: account,
    });
    return null;
}

/**
 *
 * @param hash hash from url (including '#')
 * @returns void
 */
export async function handleRedirectCallback(hash?: string) {
    if (!auth_client) throw new Error('Auth not initialized, called initializeAuth');

    try {
        console.log('handling redirect promise');
        const result = await auth_client.handleRedirectPromise(hash);

        if (result?.account) {
            console.log('Got account from result ' + result.account.username);
            auth_client.setActiveAccount(result.account);
            onLoginStateChanged.fire();
        }
    } catch (e) {
        // This code is the "customer clicked forgot password" code. We use it to redirect to the forgot password login flow, we shouldn't continue rendering so we throw.
        if (`${e}`.indexOf('AADB2C90118') >= 0)
            await auth_client?.loginRedirect({
                scopes: [apiScope],
                authority: passwordResetAuthority,
            });
        // Other errors could be due to cancelling forgot password, we just log and ignore these.
        console.log('Error completing login flow', e);
    }
}
