export type EventListener<TArg = void> = (a: TArg) => unknown;

export class EventEmitter<TArg = void> {
    listeners: EventListener<TArg>[] = [];

    on(listener: EventListener<TArg>): () => void {
        this.listeners.push(listener);
        return () => {
            this.listeners = this.listeners.filter((x) => x !== listener);
        };
    }

    fire(arg: TArg) {
        //notify any interested parties
        for (const listener of this.listeners) {
            try {
                listener(arg);
            } catch (e) {
                console.error('Error during listener callback', e);
            }
        }
    }
}
