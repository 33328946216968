import { IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';

interface HeadingProps {
    children: React.ReactNode;
    subHeading?: string;
    showBackButton?: boolean;
}

const AfnHeader: React.FC<HeadingProps> = ({ children, subHeading, showBackButton = true }) => {
    return (
        <IonHeader>
            <IonToolbar class="afn-toolbar" color="primary">
                {showBackButton && (
                    <IonButtons slot="start" class="header-buttons">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                )}
                <IonTitle class="center">{children}</IonTitle>
                {subHeading && (
                    <IonTitle size="small" class="center">
                        {subHeading}
                    </IonTitle>
                )}
            </IonToolbar>
        </IonHeader>
    );
};

export default AfnHeader;
