import { useEffect, useRef, useState } from 'react';
import { ProjectAsset } from './ApiModel';
import AssetsStore from './stores/AssetsStore';
import AssetStore from './stores/AssetStore';

export function useProjectAssets(projectId: string): ProjectAsset[] {
    const assetsStore = useRef(new AssetsStore()).current;

    //setup state that react will "react" to
    const [assets, setAssets] = useState<ProjectAsset[]>(assetsStore.assets);

    // Update the state on change
    useEffect(() => {
        const releaseListener = assetsStore.onChange.on(() => setAssets(assetsStore.assets));
        assetsStore.loadForProject(projectId);
        return releaseListener;
    }, []);

    return assets;
}

export function useProjectAsset(projectId: string, assetId: string): [ProjectAsset | undefined, AssetStore] {
    //setup state that react will "react" to
    const assetStore = useRef(new AssetStore()).current;

    const [asset, setAsset] = useState<ProjectAsset | undefined>(undefined);

    // Update the state on change
    useEffect(() => {
        const releaseListener = assetStore.onChange.on((a) => setAsset(a));
        assetStore.loadForProjectAndId(projectId, assetId);
        return releaseListener;
    }, []);

    return [asset, assetStore];
}
