import * as localforage from 'localforage';
import { AssetYear, Indicator, ProjectAsset, ProjectSummary } from './ApiModel';
import { AttachmentCacheEntry } from './helper/AttachmentHelper';
import { AttachmentUpdate, MeasurementUpdate, SiteUpdate } from './SyncHelper';

localforage.config({
    name: 'Accounting For Nature',
});

const AssetYearsKey = (projectId: string, assetId: string) => `Projects/${projectId}/Assets/${assetId}/Years`;

const AssetsKey = (projectId: string) => `Projects/${projectId}/Assets`;

const ProjectsKey = `Projects`;

const IndicatorsKey = `Indicators`;

const MeasurementUpdatesKey = (projectId: string) => `Projects/${projectId}/MeasurementUpdates`;

const SiteUpdatesKey = (projectId: string) => `Projects/${projectId}/SiteUpdates`;

const AttachmentUpdatesKey = (projectId: string) => `Projects/${projectId}/AttachmentUpdates`;

const AttachmentCacheKey = (projectId: string) => `Projects/${projectId}/AttachmentCache`;

class Storage {
    private setItem<T>(key: string, value: T): Promise<T> {
        return localforage.setItem<T>(key, value);
    }

    private getItem<T>(key: string): Promise<T | null> {
        return localforage.getItem<T>(key);
    }

    async getAssetYears(projectId: string, assetId: string): Promise<AssetYear[]> {
        return (await this.getItem<AssetYear[]>(AssetYearsKey(projectId, assetId))) ?? [];
    }

    setAssetYears(projectId: string, assetId: string, assetYears: AssetYear[]): Promise<AssetYear[]> {
        return this.setItem(AssetYearsKey(projectId, assetId), assetYears);
    }

    async getAssets(projectId: string): Promise<ProjectAsset[]> {
        return (await this.getItem<ProjectAsset[]>(AssetsKey(projectId))) ?? [];
    }

    setAssets(projectId: string, assets: ProjectAsset[]): Promise<ProjectAsset[]> {
        return this.setItem(AssetsKey(projectId), assets);
    }

    async getProjects(): Promise<ProjectSummary[]> {
        return (await this.getItem<ProjectSummary[]>(ProjectsKey)) ?? [];
    }

    setProjects(projects: ProjectSummary[]): Promise<ProjectSummary[]> {
        return this.setItem<ProjectSummary[]>(ProjectsKey, projects);
    }

    async getMeasurementUpdates(projectId: string): Promise<MeasurementUpdate[]> {
        return (await this.getItem<MeasurementUpdate[]>(MeasurementUpdatesKey(projectId))) ?? [];
    }

    setMeasurementUpdates(projectId: string, updates: MeasurementUpdate[]): Promise<MeasurementUpdate[]> {
        return this.setItem<MeasurementUpdate[]>(MeasurementUpdatesKey(projectId), updates);
    }

    async getSiteUpdates(projectId: string): Promise<SiteUpdate[]> {
        return (await this.getItem<SiteUpdate[]>(SiteUpdatesKey(projectId))) ?? [];
    }

    setSiteUpdates(projectId: string, updates: SiteUpdate[]): Promise<SiteUpdate[]> {
        return this.setItem<SiteUpdate[]>(SiteUpdatesKey(projectId), updates);
    }

    async getIndicators(): Promise<Indicator[]> {
        return (await this.getItem<Indicator[]>(IndicatorsKey)) ?? [];
    }

    async setIndicators(indicators: Indicator[]): Promise<Indicator[]> {
        return this.setItem<Indicator[]>(IndicatorsKey, indicators);
    }

    async getAttachmentUpdates(projectId: string): Promise<AttachmentUpdate[]> {
        return (await this.getItem<AttachmentUpdate[]>(AttachmentUpdatesKey(projectId))) ?? [];
    }

    async setAttachmentUpdates(projectId: string, updates: AttachmentUpdate[]): Promise<AttachmentUpdate[]> {
        return this.setItem<AttachmentUpdate[]>(AttachmentUpdatesKey(projectId), updates);
    }

    async getAttachmentCache(projectId: string): Promise<AttachmentCacheEntry[]> {
        return (await this.getItem<AttachmentCacheEntry[]>(AttachmentCacheKey(projectId))) ?? [];
    }

    async setAttachmentCache(projectId: string, updates: AttachmentCacheEntry[]): Promise<AttachmentCacheEntry[]> {
        return this.setItem<AttachmentCacheEntry[]>(AttachmentCacheKey(projectId), updates);
    }
}

const storage = new Storage();
export default storage;
