import { EventEmitter } from '../helper/EventHelper';
import { AssetYear, Attachment } from '../ApiModel';
import storage from '../Storage';
import MeasurementUpdatesStore from './MeasurementUpdatesStore';
import { orderBy } from '../helper/ArrayHelper';
import { AttachmentCreateUpdate, AttachmentDeleteUpdate, MeasurementUpdate } from '../SyncHelper';
import produce from 'immer';
import { generateId } from '../helper/IdHelper';
import AttachmentCacheStore from './AttachmentCacheStore';
import AttachmentUpdatesStore from './AttachmentUpdatesStore';

type AttachmentCreateParams = Omit<AttachmentCreateUpdate, 'updateKind' | 'mediaType'>;
type AttachmentReplaceParams = Omit<AttachmentCreateParams, 'attachmentType'>;
type AttachmentDeleteParams = Omit<AttachmentDeleteUpdate, 'updateKind'>;
export default class AssetYearStore {
    onChange = new EventEmitter<AssetYear | undefined>();
    assetYear: AssetYear | undefined = undefined;
    projectId = '';
    assetId = '';

    measurementUpdateStore = new MeasurementUpdatesStore();
    attachmentCacheStore = new AttachmentCacheStore();
    attachmentUpdateStore = new AttachmentUpdatesStore();

    async loadMostRecentForAsset(projectId: string, assetId: string) {
        this.projectId = projectId;
        this.assetId = assetId;
        const assetYears = await storage.getAssetYears(this.projectId, this.assetId);
        if (assetYears.length == 0) {
            this.assetYear = undefined;
        } else {
            this.assetYear = orderBy(assetYears, (ay) => ay.year, 'desc')[0];
        }
        await this.measurementUpdateStore.loadForProject(this.projectId);
        await this.attachmentCacheStore.loadForProject(this.projectId);
        await this.attachmentUpdateStore.loadForProject(this.projectId);
        this.onChange.fire(this.assetYear);
    }

    async updateMeasurement(update: MeasurementUpdate) {
        this.measurementUpdateStore.addUpdate(update);
        if (!this.assetYear) return;

        const newYear = produce(this.assetYear, (assetYear) => {
            const assessmentUnit = assetYear.measurementData.find(
                (md) => md.assessmentUnitId == update.assessmentUnitId
            );
            if (!assessmentUnit) throw new Error("Couldn't find assessment unit for update");

            const existingIndicatorData = assessmentUnit.indicatorData.find(
                (indd) => indd.indicatorId == update.indicatorId && indd.siteId == update.siteId
            );

            if (existingIndicatorData) {
                existingIndicatorData.measurementValue = update.update.measurementValue;
                existingIndicatorData.measurementDate = update.update.measurementDate;
                console.log('updated value', existingIndicatorData.id);
            } else {
                //we must be for a new site
                assessmentUnit.indicatorData.push({
                    siteId: update.siteId,
                    indicatorId: update.indicatorId,
                    id: 'newItem' + generateId(),
                    attachments: [],
                    measurementDate: update.update.measurementDate,
                    measurementValue: update.update.measurementValue,
                });
                console.log('added new indicator data for site ', update.siteId);
            }
        });
        this.assetYear = newYear;
        this.save();
        this.onChange.fire(this.assetYear);
    }

    async createAttachment(attach: AttachmentCreateParams) {
        if (!this.assetYear) throw new Error('Asset year not loaded');

        // create the attachment
        const res = await fetch(attach.localpath);

        if (!res.ok) throw new Error("Couldn't read attachment path");
        const blob = await res.blob();

        const attachment: Attachment = {
            id: attach.attachmentId,
            contentType: blob.type,
            created: new Date().toISOString(),
            name: attach.filename ?? '',
            sizeBytes: blob.size,
            type: attach.attachmentType,
            uploadedDate: new Date().toISOString(),
            metadata: {},
        };

        //Cache the file content
        const cacheEntry = await this.attachmentCacheStore.storeAttachment(attachment.id, blob);

        this.assetYear = produce(this.assetYear, (ay) => {
            const md = ay.measurementData.find((md) => md.assessmentUnitId == attach.assessmentUnitId);
            if (!md) throw new Error('missing measurement data');
            if (attach.indicatorId) {
                let existingIndicatorData = md.indicatorData.find(
                    (sd) => sd.indicatorId == attach.indicatorId && sd.siteId == attach.siteId
                );
                if (!existingIndicatorData) {
                    existingIndicatorData = {
                        id: 'new-' + generateId(),
                        indicatorId: attach.indicatorId,
                        siteId: attach.siteId,
                        attachments: [],
                    };
                    md.indicatorData.push(existingIndicatorData);
                }

                attachment.indicatorDataId = existingIndicatorData.id;
                existingIndicatorData.attachments.push(attachment);
            } else {
                let existingSiteData = md.siteData.find((sd) => sd.siteId == attach.siteId);
                if (!existingSiteData) {
                    const newSiteData = {
                        siteId: attach.siteId,
                        id: 'new-' + generateId(),
                        attachments: [],
                    };
                    md.siteData.push(newSiteData);
                    existingSiteData = newSiteData;
                }

                attachment.siteDataId = existingSiteData.id;
                existingSiteData.attachments.push(attachment);
            }
        });

        this.onChange.fire(this.assetYear);
        this.save();

        const updateEntry: AttachmentCreateUpdate = {
            ...attach,
            //Queue the sync after updating to point at our cached file and new attachment
            localpath: cacheEntry.localfilepath,
            mediaType: blob.type,
            updateKind: 'create',
        };

        await this.attachmentUpdateStore.addUpdate(updateEntry);
        return attachment;
    }

    async replaceAttachment(attach: AttachmentReplaceParams) {
        if (!this.assetYear) throw new Error('Asset year not loaded');
        // load our data
        const res = await fetch(attach.localpath);

        if (!res.ok) throw new Error("Couldn't read attachment path");
        const blob = await res.blob();

        //Update Our Cache
        const cacheEntry = await this.attachmentCacheStore.storeAttachment(attach.attachmentId, blob);

        //save the update
        await this.attachmentUpdateStore.addUpdate({
            ...attach,
            updateKind: 'replace',
            mediaType: blob.type,
            localpath: cacheEntry.localfilepath,
        });

        //Update our attachment
        this.assetYear = produce(this.assetYear, (ay) => {
            const md = ay.measurementData.find((md) => md.assessmentUnitId == attach.assessmentUnitId);
            if (!md) throw new Error('missing measurement data');

            let existingAttachment: Attachment | undefined = undefined;
            if (attach.indicatorId) {
                //TODO Indicator Attachments
                const existingIndicatorData = md.indicatorData.find(
                    (indd) => indd.indicatorId == attach.indicatorId && indd.siteId == attach.siteId
                );
                if (!existingIndicatorData) throw new Error('Missing indicatorData for attachment');
                existingAttachment = existingIndicatorData.attachments.find((a) => a.id == attach.attachmentId);
            } else {
                const existingSiteData = md.siteData.find((sd) => sd.siteId == attach.siteId);
                if (!existingSiteData) throw new Error('Missing site data for attachment');
                existingAttachment = existingSiteData.attachments.find((a) => a.id == attach.attachmentId);
            }

            if (!existingAttachment) throw new Error('Attachment not found');
            existingAttachment.contentType = blob.type;
            existingAttachment.sizeBytes = blob.size;
            existingAttachment.name = attach.filename ?? '';
            existingAttachment.uploadedDate = new Date().toISOString();
        });

        this.onChange.fire(this.assetYear);
        this.save();
    }

    async deleteAttachment(attach: AttachmentDeleteParams) {
        if (!this.assetYear) throw new Error('Asset year not loaded');

        //clear Our Cache
        await this.attachmentCacheStore.removeEntry(attach.attachmentId);

        //remove our attachment
        this.assetYear = produce(this.assetYear, (ay) => {
            const md = ay.measurementData.find((md) => md.assessmentUnitId == attach.assessmentUnitId);
            if (!md) throw new Error('missing measurement data');

            if (attach.indicatorId) {
                const existingIndicatorData = md.indicatorData.find(
                    (indd) => indd.indicatorId == attach.indicatorId && indd.siteId == attach.siteId
                );
                if (!existingIndicatorData) throw new Error('Missing indicatorData for attachment');
                const existingIdx = existingIndicatorData.attachments.findIndex((a) => a.id == attach.attachmentId);
                if (existingIdx >= -1) existingIndicatorData.attachments.splice(existingIdx, 1);
            } else {
                const existingSiteData = md.siteData.find((sd) => sd.siteId == attach.siteId);
                if (!existingSiteData) throw new Error('Missing site data for attachment');
                const existingIdx = existingSiteData.attachments.findIndex((a) => a.id == attach.attachmentId);
                if (existingIdx >= -1) existingSiteData.attachments.splice(existingIdx, 1);
            }
        });

        this.onChange.fire(this.assetYear);
        this.save();

        await this.attachmentUpdateStore.addUpdate({
            ...attach,
            updateKind: 'delete',
        });
    }

    async save() {
        if (!this.assetYear) return;
        const assetYears = await storage.getAssetYears(this.projectId, this.assetId);
        const existingIdx = assetYears.findIndex((a) => a.id == this.assetYear?.id);
        if (existingIdx >= 0) {
            assetYears[existingIdx] = this.assetYear;
        }
        await storage.setAssetYears(this.projectId, this.assetId, assetYears);
    }
}
